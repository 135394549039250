<template>
  <div
        class="overflow-hidden hidden-md-and-up"
        height="80"
  
    >
        <div>
            <v-btn
                class="mb-8"
                color="grey"
                outlined
               
                @click.stop="drawer = !drawer"
                small
            >
                <v-icon>mdi-filter-outline</v-icon>filter
            </v-btn>
        </div>

        <v-navigation-drawer
        fixed
        class="hidden-md-and-up"
        v-model="drawer"
        >
        <template>
            <v-card class="mx-auto elevation-0" width="300">
                <sidebar-filter/>
            </v-card>
        </template>

        </v-navigation-drawer>
    </div>
</template>

<script>
import SidebarFilter from '../layout/SidebarFilter';
export default {
    components: {SidebarFilter},
    data: () => ({
      drawer: false,
      group: null,
        checkbox1: false,
        items: [
            'Houses', 'Office Space', 'Open Space', 'Lands', 'Apartment',
        ],
        SelectMenu: [
            'Location', 'Developer', 'Property Type', 'Price Range',
        ],
        sort: 'Sort By',
    }),

    watch: {
      group () {
        this.drawer = false
      },
    },
  }
</script>