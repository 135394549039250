<template>
  <div class="mb-n10"  id="product-details">
    <div class="mt-10" v-if="skeLoader">
      <skeleton-loader/>
    </div>
    <div v-if="skeLoader == false">
      <vueper-slides
        bullets-outside
        fade
        fixed-height="350px"
        :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 2 } }"
        :visible-slides="3"
        :slide-ratio="1 / 4"
        :arrows-outside="false">
        <vueper-slide v-for="image in product.product_images" :key="image.id"  :image="image.full"/>
      </vueper-slides>
      <div class="px-1 px-md-6">
        <div
            class="cyan--text text--darken-3 px-1 px-4-md mb-2 mt-n6 text-h6 text-md-h5"
          >
            ₦{{product.converted_price}}
            <span v-show="product.duration" class="text-caption grey--text">
              {{`(${product.duration})`}}
            </span>
        </div>
        <span class="mt-n10 px-1 text-subtitle-1 font-weight-normal float-right lime darken-3 white--text"> 
          {{product.product_type}} 
        </span>
        <div
          class="px-1 px-4-md grey--text text--darken-3 mb-1 text-h6 text-md-h4"
          
        >
          {{product.title}}
        </div>
        <div class="d-flex text-subtitle-2">
          <div class="">
            <v-icon class="mr-2 mt-n1">mdi-map-marker-outline</v-icon>
            <span class="lime--text text--darken-4 ">{{product.address}}</span>
            <div class="grey--text mt-2 px-2"> {{`${product.state.name}, ${product.lga.name}`}}</div>
          </div>
        </div>
        <v-container fluid>
          <v-row>
            <v-col 
              cols="12"
              md="8"
            > 
              <div class="mt-5">
                <v-card style=" border: 1px solid #E0E0E0" elevation="0">
                  <v-card-title>Overview</v-card-title>
                  <v-card-subtitle>Details</v-card-subtitle>
                  <div class="d-flex mt-n4">
                    <v-card-text><v-icon>mdi-bed</v-icon> {{product.bedroom}} Bedrooms</v-card-text>
                    <v-card-text
                      ><v-icon>mdi-shower</v-icon> {{product.bathroom}} Bathrooms</v-card-text
                    >
                    <v-card-text><v-icon>mdi-ruler</v-icon> {{product.size}} Square meter</v-card-text>
                  </div>
                </v-card>
                <div class="mt-4">
                  <v-tabs
                    v-model="tab"
                    align-with-title
                    
                  >
                    <v-tabs-slider color="yellow"></v-tabs-slider>

                    <v-tab
                      
                    >
                      Description
                    </v-tab>
                    <v-tab
                      v-show=" filteredProduct.length > 0"
                    >
                      Features
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      
                    >
                    
                      <v-card class="mt-5"  elevation="0"  style=" border: 1px solid #E0E0E0">
                        <v-card-text>
                          {{product.description}}
                        </v-card-text>
                      </v-card>
                      <v-card class="mt-5" style=" border: 1px solid #E0E0E0" elevation="0">
                          <v-card-title> Address </v-card-title>
                          <v-card-text class="mt-n2" >
                            {{product.address}}
                          </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                      
                    >
                      <v-card flat>
                        <v-card-text >
                          <div style=" border: 1px solid #E0E0E0">
                            <v-simple-table height="400px" v-show=" filteredProduct.length > 0">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      Name
                                    </th>
                                    <th class="text-left">
                                      Value
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in filteredProduct"
                                    :key="item.name"
                                  >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.value == 1 ? 'Yes': 'No' }}</td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <div class="d-flex justify-center">
              </div>
              <div class="mt-4">
                <v-card
                  class=" py-4 grey lighten-3"
                  
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-2">
                        Agent
                      </div>
                      <div class="mt-n4 text-h6 mb-1">
                        {{productAuthor.first_name+' '+productAuthor.last_name}}
                      </div>
                    </v-list-item-content>

                    <v-list-item-avatar
                      tile
                      size="80"
                      color="grey"
                    >
                      <v-img
                        height=""
                        :src="productAuthor.image"
                      >
                      </v-img> 
                    </v-list-item-avatar>
                  </v-list-item>

                  <v-card-actions class="mt-n4 text-center">
                    <v-btn
                    
                      rounded-lg
                      class="grey darken-2 white--text"
                      color=""
                      @click="toggleContact"
                    >
                      <v-icon
                        dark
                        right
                        class=""
                      >
                        mdi-phone
                      </v-icon>
                      {{phoneNumber? productAuthor.phone_number : 'Contact'}}
                    </v-btn>
                    <v-btn
                      @click="toggleChat"
                      outlined
                      rounded-lg
                      class="grey darken-2 white--text"
                      color=""
                    >
                      <v-icon
                        lime
                        right
                      >
                        mdi-chat
                      </v-icon>
                      Message
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <div class="text-center" v-show="showChat">
                  <v-textarea
                    outlined
                    class="mt-2"
                    label="type message here"
                    v-on:keyup.enter="postMessage" 
                    v-model="messageContent"
                  ></v-textarea>
                  <div class=" mt-n4">
                    <v-btn
                        v-show="loading == false"
                      color="green"
                      dark
                      block
                      @click="postMessage"
                    >
                      Send
                    </v-btn>
                    <v-progress-circular
                      v-show="loading"
                      :width="3"
                      class="mb-4 text-center"
                      color="green"
                      indeterminate
                    >
                    </v-progress-circular>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="mt-8" v-if="relatedProducts.length > 0">
            <h2 class="mb-n2 mb-md-4">Similar Properties</h2>

            <!--md and up slider control -->
            <v-row class="px-16 mb-n9 hidden-sm-and-down" v-show="relatedProducts.length > 3">
              <v-col>
                <v-btn icon @click="scrollRight()">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn icon @click="scrollLeft()">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>

             <!--sm and down slider control -->
            <v-row class="px-16 mb-n9 hidden-md-and-up" v-show="relatedProducts.length > 1">
              <v-col>
                <v-btn icon @click="scrollRight()">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn icon @click="scrollLeft()">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div
            
              class="testimonial-container py-8 ml-n6"
              :class="{
              
              'px-2': $vuetify.breakpoint.smAndDown,
              }"
              :id="`testimonial`"
            >
              <v-card
                color="grey lighten-5"
                class="testimonial-card pa-2 "
                v-for="product in relatedProducts"
                :key="product.id"
              > 
                <router-link class="text-decoration-none black--text" :to="{ path: `/product-details/${product.slug}` }" >
                  <v-img
                    height="200px"
                    :src="product.product_images.length > 0 ? product.product_images[0].full: ''"
                  >
                  </v-img>
                  <div class="mt-4 ">
                    <div class="cyan--text text--darken-4 font-weight-bold">
                      <div>₦ {{product.converted_price}} 
                          <span v-show="product.duration" class="text-caption grey--text">
                              {{`(${product.duration})`}}
                          </span>
                      </div>
                      <span class="mt-n6 px-1 text-caption font-weight-normal float-right lime darken-3 white--text"> 
                          {{product.product_type}} 
                      </span>
                    </div>
                  </div>
                  <v-divider class="divider-color mb-n2" width="50"></v-divider>
                  <h4 class="mt-4 grey--text text--darken-3" style="height: 45px">
                      {{product.title | truncate(63) }}
                  </h4>
                  <div class=" text-caption grey--text text-lighten-2 mb-n6 mt-2">
                      <v-icon small>mdi-map-marker</v-icon>{{`${product.state.name}, ${product.lga.name}`}}
                  </div>
                  <div class="mt-8 mb-n2">
                      <span class="text-caption">{{product.listed_in}}</span>
                      <v-icon
                          v-show="product.bathroom"
                          class="ml-4"
                          small
                          >
                              mdi-shower
                      </v-icon>
                      <span  class="text-caption ml-1">{{product.bathroom}}</span>
                      <v-icon
                          v-show="product.bedroom"
                          class="ml-4"
                          small
                          >
                              mdi-bed
                      </v-icon>
                      <span  class="text-caption ml-1">{{product.bedroom}}</span>
                  </div>
                </router-link>
                <div class="mb-2 mt-4">
                    <router-link class="mt-14 text-decoration-none black--text" :to="{ path: `/agent/products/${product.user.id}` }">
                      <div class="text-subtitle-2 mt-8 text-capitalize ">
                        Agent:
                          
                        <span class="lime--text text--darken-4 ml2">
                            {{product.user.agent.title? product.user.agent.title : product.user.full_name}}
                        </span>
                        <v-icon v-show="product.user.verify && product.user.verify.status == 1" small class="green--text">mdi-check-decagram</v-icon>
                      </div>
                    </router-link>
                    <div  v-show="product.developer" class=" text-caption text-capitalize ">
                        developer: <span class="grey--text text-lighten-2"> {{product.developer}}</span>
                    </div>
                </div>
              </v-card>
            </div>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
  import SkeletonLoader from '../layout/SkeletonLoader';
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'
  import { mapActions, mapState } from "vuex";

export default {
  components: {
    VueperSlides, VueperSlide, SkeletonLoader
  },
  metaInfo() {
		return {
			title: `${this.convertToSlug(this.$route.params.slug)} | Housefinder.com.ng`,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: `${this.product.description}`
				}
			]
		}
	},
  data() {
    return {
      productAuthor: '',
      skeLoader: false,
      loading: false,
      product: '',
      features: [],
      model: null,
      phoneNumber: false,
      showChat: false,
      messageContent: '',
      relatedProducts: [],
      tab: null,
    }
  },
  computed: {
    ...mapState(["editedProduct"]),
    filteredProduct () {
      return this.features.filter(f => f.value == 1 );
    },
  },
  methods: {
    ...mapActions(["findProduct","createChatSession", "sendChat"]),
    toggleContact(){
      this.phoneNumber = !this.phoneNumber;
    },
    toggleChat(){
      this.showChat = !this.showChat;
    },
    async getData(slug){
      this.skeLoader = true;
      await this.findProduct(slug)
      this.product = this.editedProduct.data;
      this.relatedProducts = this.editedProduct.related_products;
      this.features = this.editedProduct.data.features;
      this.productAuthor = this.editedProduct.data.user
      this.skeLoader = false;
    },
    async postMessage(){
      event.preventDefault()
      let token = localStorage.getItem('token');
      let user = localStorage.getItem('authUser')
      let authUser = JSON.parse(user);
      if (token) {
        if(this.messageContent != '' && this.product.user_id  != authUser.id){
          this.loading = true;
          let friendId = {friend_id: this.product.user_id};
          let session_id = await this.createChatSession(friendId)
          if(session_id) {
            let data = {content: this.messageContent, sessionId: session_id, to_user: this.product.user_id}
            await this.sendChat(data)
            this.$router.push(`/user/chats`);
          }   
        }
      }
      else{
        this.$router.push(`/login?redirect=${this.$router.currentRoute.path}`);
        //this.$router.push({ name: '/login', query: { redirect: this.$router.currentRoute.path } });
      }
    },
    scrollLeft() {
			var elmnt = document.getElementById('testimonial')
			elmnt.scrollLeft += 345
		},
		scrollRight() {
      var elmnt = document.getElementById('testimonial')
      elmnt.scrollLeft -= 345
    },
    convertToSlug(Text){
      return Text.replace(/-/g,' ');
    }
  },
  watch: { 
    $route(to, from) { 
			if(to.params.slug !== from.params.slug){
				this.getData(this.$route.params.slug);
				window.scrollTo(0,0);
			} 
		} 
	},
  created(){
    this.getData(this.$route.params.slug);
  }
};
</script>
<style lang="scss" scoped>
#product-detail {
  background-color: #f7f7f7;
}

@media (min-width: 960px) {
  #left-col {
    width: 65%;
  }
}

@media (min-width: 960px) {
  #right-col {
    width: 30%;
  }
}

.divider-color{
    background: #896D19;
}   
.img-container {
    height: 450px;
    width: 450px;
    border-radius: 100%;
    background: #fff;
}
.testimonial-container {

    display: flex;
    flex-flow: row;
    min-height: 392px;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
    overflow-x: scroll !important;
    .testimonial-card {
        flex: 0 0 auto;
    
        box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.25) !important;
        margin-left: 2.5%;
        width: 320px;
        border-radius: 5px;
        .testimonial-text {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;

            color: #6c6868;
        }
        .testimonial-name {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 28px;

            color: #454343;
        }
    }
}

@media only screen and (max-width: 600px) {
    .heroimg {
        width: 100% !important;
        height: 400px;
    }
    .img-container {
        width: 300px;
        height: 300px;
        img {
            width: 100%;
        }
    }
    .testimonial-card {
        width: 400px !important;
    }
}
</style>
