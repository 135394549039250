<template>
	<div class="container-fluid">
		<v-row
			style=" border: 1px solid #E0E0E0"
			class="rounded-lg pa-2 mb-8"
			no-gutters
	>   
			<v-col  v-for="category in 	filteredCategory" v-bind:key="category.id">
				<div 
					v-on:click="setCurrent(category.slug)" 
					class="text-center  pa-1"
					v-bind:class="category.slug == currentCategory ? 'category' : ' '"
				>
					<img height="70" class=""  :src="category.image">
					<div class="mt-n3 text-subtitle-2">{{category.name}}</div>
				</div>
			</v-col>
					
		</v-row>
	</div>
</template>
<script>
import { mapState, mapActions, mapMutations} from "vuex";
export default {
	computed: {
		...mapState(["categoriesData", "currentCategory"]),
		filteredCategory(){
			return this.categoriesData.filter(p => p.menu == 1);
		}
	},
	methods: {
		...mapActions(["setCurrentCategory"]),
		...mapMutations(["_setSkeloader"]),
		setCurrent(slug){
			
			//this.setCurrentCategory(slug)
			this.$router.replace(slug)
		}
	}
}
</script>
<style>
	.category{
		background-color: #E0E0E0;
	}
</style>