<template>
    <div class="py-6" v-show="pages.length > 0">
        <v-pagination
            v-model="currentPage"
            :length="pageCount"
            :total-visible="5"
            @input="handlePageChange"
        >
        </v-pagination>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
    computed: {
        ...mapState(["currentPage", "pages", "AgentDetail"]),
        ...mapGetters(["pageCount"]),
    },
    methods: {
    ...mapActions(["_setAgentProductCurrentPage"]),
    handlePageChange(value) {
        let data = {page: value, agentId: this.AgentDetail.id}
        this._setAgentProductCurrentPage(data)
        window.scrollTo(0,0);
       
    }
  }
}
</script>