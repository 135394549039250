<template>
    <div class="py-6" v-show="pages.length > 0">
        <v-pagination
            v-model="currentPage"
            :length="pageCount"
            :total-visible="5"
            @input="handlePageChange"
        >
        </v-pagination>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
    computed: {
        ...mapState({currentPage : state => state.request.currentPage, 
            pages : state => state.request.pages}),
        ...mapGetters({pageCount: "request/pageCount"}),
    },
    methods: {
    ...mapActions({setCurrentPage: "request/setCurrentPage", setPageSize: "request/setPageSize"}),
    handlePageChange(value) {
        this.setCurrentPage(value)
        window.scrollTo(0,0);
       
    }
  }
}
</script>