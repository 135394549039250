<template>
  <div
    class="rounded-lg grey lighten-2"
    :class="{ 'pa-10': $vuetify.breakpoint.mdAndUp }"
  >
    <div class="pa-4">
      <v-select
        background-color="white"
        class=""
        :items="SortItems"
        label="Sort By"
        v-model="sort"
        v-on:change="sortProduct"
        item-text="name"
        item-value="value"
        outlined
        dense
        :disabled="skeloader == true"
      >
      </v-select>
      <div class="mb-4 white pa-1">
        <v-radio-group v-model="listing">
          <v-radio
            v-for="item in listingIn"
            :key="item.name"
            :label="item.name"
            v-on:click="setListed(item)"
            :value="item.value"
            :disabled="skeloader == true"
          ></v-radio>
        </v-radio-group>
      </div>
      <v-select
        style=""
        background-color="white"
        class="select1"
        v-on:change="setPrice"
        :items="priceItems"
        item-text="name"
        item-value="value"
        v-model="priceRange"
        label="Price Range"
        outlined
        dense
        :disabled="skeloader == true"
      >
      </v-select>
      <v-select
        style=""
        :items="PropertyType"
        item-text="name"
        item-value="value"
        v-model="property_type"
        v-on:change="changePropertyType"
        label="Property Type"
        outlined
        background-color="white"
        dense
        :disabled="skeloader == true"
      >
      </v-select>
      <v-select
        style=""
        :items="bedrooms"
        item-text="name"
        item-value="value"
        v-model="bedroom"
        v-on:change="setBedroomNumber"
        label="bedroom"
        outlined
        background-color="white"
        dense
        :disabled="skeloader == true"
      >
      </v-select>
      <v-select
        style=""
        class=""
        background-color="white"
        :items="states"
        item-text="name"
        item-value="id"
        v-model="state_id"
        v-on:change="onChange"
        label="Select State"
        outlined
        dense
        :disabled="skeloader == true"
      >
      </v-select>
      <v-select
        v-show="toggleLga"
        class=""
        background-color="white"
        :items="lgas"
        label="select lga"
        item-text="name"
        item-value="id"
        v-on:change="set_lga"
        outlined
        v-model="lga_id"
        dense
        :disabled="skeloader == true"
      >
      </v-select>
      <v-select
        style=""
        background-color="white"
        :items="categories"
        label="Categories"
        v-on:change="changeCategory"
        outlined
        v-model="currentCategory"
        item-text="name"
        item-value="slug"
        dense
        :disabled="skeloader == true"
      >
      </v-select>
      <v-btn
        depressed
        color="#0062DF"
        class=" darken-4 white--text"
        @click="resetFilter"
      >
        Clear Filter
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      checkbox1: false,
      SortItems: [
        { name: "Newest First", value: "DESC" },
        { name: "Oldest First", value: "ASC" },
      ],
      priceItems: [
        { name: "All Price", value: "" },
        { name: "Less than 50,000", value: "0-50000" },
        { name: "Less than 100,000", value: "0-100000" },
        { name: "Less than 250,000", value: "0-250000" },
        { name: "Less than 500,000", value: "0-500000" },
        { name: "Less than 750,000", value: "0-750000" },
        { name: "Less than 1,000,000", value: "0-1000000" },
        {
          name: "Above 1,000,000 and below 5,000,000",
          value: "1001000-5000000",
        },
        {
          name: "Above 5,000,000 and below 10,000,000",
          value: "5001000-10000000",
        },
        {
          name: "Above 10,000,000 and below 20,000,000",
          value: "10001000-20000000",
        },
        { name: "Above 20,000,000", value: "20000000-1000000000" },
      ],
      listingIn: [
        { name: "Land", value: "land" },
        { name: "Detached", value: "detached" },
        { name: "Semi Detached", value: "semi detached" },
        { name: "Furnished", value: "funished" },
        { name: "Serviced", value: "serviced" },
      ],
      PropertyType: [
        { name: "Short Let", value: "For Short Let" },
        { name: "Buy", value: "For Sale" },
        { name: "Rent", value: "For Rent" },
      ],
      bedrooms: [
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
        { name: "4", value: 4 },
        { name: "5", value: 5 },
        { name: "6", value: 6 },
        { name: "7", value: 7 },
        { name: "8", value: 8 },
        { name: "9", value: 9 },
        { name: "10", value: 10 },
      ],
    };
  },
  computed: {
    ...mapState([
      "listing",
      "sort",
      "priceRange",
      "state_id",
      "lga_id",
      "bedroom",
      "toggleLga",
      "currentCategory",
      "property_type",
      "skeloader",
    ]),
    ...mapGetters(["categories", "states", "lgas", "processedProducts"]),
  },
  methods: {
    ...mapActions([
      "getProductList",
      "setSort",
      "setPriceRange",
      "setBedroom",
      "setListing",
      "setPropertyType",
      "getLga",
      "setLga",
      "setState",
      "setCurrentCategory",
    ]),
    ...mapMutations(["_toggleLga", "_setSkeloader", "clearFilter"]),
    async onChange(event) {
      window.scrollTo(500, 0);
      this._setSkeloader(true);
      await this.setState(event);
      this.getLga(event);
      this._toggleLga(true);
      this._setSkeloader(false);
    },
    async set_lga(event) {
      window.scrollTo(500, 0);
      this._setSkeloader(true);
      await this.setLga(event);
      this._setSkeloader(false);
    },
    async sortProduct(event) {
      window.scrollTo(500, 0);
      this._setSkeloader(true);
      await this.setSort(event);
      this._setSkeloader(false);
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { sort: event }),
      });
    },
    async setBedroomNumber(event) {
      window.scrollTo(500, 0);
      this._setSkeloader(true);
      await this.setBedroom(event);
      this._setSkeloader(false);
    },
    async setPrice(event) {
      window.scrollTo(500, 0);
      this._setSkeloader(true);
      await this.setPriceRange(event);
      this._setSkeloader(false);
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { priceRange: event }),
      });
    },
    async setListed(item) {
      window.scrollTo(500, 0);
      this._setSkeloader(true);
      await this.setListing(item.value);
      this._setSkeloader(false);
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { listed: item.value }),
      });
    },
    changeCategory(event) {
      this.$router.push(`/properties/${event}`);
    },
    async changePropertyType(event) {
      window.scrollTo(500, 0);
      this._setSkeloader(true);
      await this.setPropertyType(event);
      this._setSkeloader(false);
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { propertyType: event }),
      });
    },
    async resetFilter() {
      window.scrollTo(500, 0);
      this._setSkeloader(true);
      this._setSkeloader(true);
      this.clearFilter();
      await this.getProductList();
      this._setSkeloader(false);
    },
  },
};
</script>
