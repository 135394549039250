<template>
  <div class="rounded-lg d-flex  mb-6" flat>
    <v-select
      style=""
      class="mr-4"
      :items="states"
      item-text="name"
      item-value="id"
      v-model="state"
      v-on:change="onChange"
      label="Select State"
      outlined
      dense
    >
    </v-select>
    <v-select
      style=""
      class="mr-4"
      :items="lgas"
      label="Select LGA"
      item-text="name"
      item-value="id"
      outlined
      v-model="lga"
      dense
    >
    </v-select>
    <v-select
      style=""
      class="mr-4"
      :items="PropertyType"
      item-text="name"
      item-value="value"
      v-model="property_type"
      label="Property Type"
      outlined
      dense
    >
    </v-select>
    <v-select
      style=""
      class="mr-4"
      :items="priceItems"
      label="Price Range"
      outlined
      item-text="name"
      v-model="price_range"
      item-value="value"
      dense
    >
    </v-select>
    <v-select
      style=""
      class="mr-4"
      :items="categories"
      label="Categories"
      outlined
      v-model="cat"
      item-text="name"
      item-value="slug"
      dense
    >
    </v-select>
    <v-btn
      v-show="loading == false"
      @click="submit"
      color="#0062DF"
      class=" darken-4 white--text"
    >
      Search
      <v-icon dark class="ml-2">
        mdi-magnify
      </v-icon>
    </v-btn>
    <v-progress-circular
      v-show="loading"
      :width="3"
      class="mb-4"
      color="green"
      indeterminate
    >
    </v-progress-circular>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      loading: false,
      cats: [],
      state: "",
      lga: "",
      property_type: "",
      cat: "",
      price_range: "",
      PropertyType: [
        { name: "Short Let", value: "For Short Let" },
        { name: "Buy", value: "For Sale" },
        { name: "Rent", value: "For Rent" },
      ],
      priceItems: [
        { name: "All Price", value: "" },
        { name: "Less than 50,000", value: "0-50000" },
        { name: "Less than 100,000", value: "0-100000" },
        { name: "Less than 250,000", value: "0-250000" },
        { name: "Less than 500,000", value: "0-500000" },
        { name: "Less than 750,000", value: "0-750000" },
        { name: "Less than 1,000,000", value: "0-1000000" },
        {
          name: "Above 1,000,000 and below 5,000,000",
          value: "1001000-5000000",
        },
        {
          name: "Above 5,000,000 and below 10,000,000",
          value: "5001000-10000000",
        },
        {
          name: "Above 10,000,000 and below 20,000,000",
          value: "10001000-20000000",
        },
        { name: "Above 20,000,000", value: "20000000-1000000000" },
      ],
    };
  },
  computed: {
    ...mapGetters(["categories", "states", "lgas"]),
  },
  methods: {
    ...mapActions(["getLga", "getLocation", "setSearch"]),
    ...mapMutations([
      "_setPriceRange",
      "_setState",
      "_setLga",
      "_setPropertyType",
      "_setCurrentCategory",
      "_setSkeloader",
    ]),
    async submit() {
      this._setState(this.state);
      this._setPriceRange(this.price_range);
      if (this.price_range != "") {
        this.$router.replace({
          query: Object.assign({}, this.$route.query, {
            priceRange: this.price_range,
          }),
        });
      }
      this._setLga(this.lga);
      this._setPropertyType(this.property_type);

      this.$router.replace({
        query: Object.assign({}, this.$route.query, {
          property_type: this.property_type,
        }),
      });

      this._setCurrentCategory(this.cat);
      this._setSkeloader(true);
      this.loading = true;
      await this.setSearch();
      this.loading = false;
      this._setSkeloader(false);
    },
    onChange(event) {
      this.getLga(event);
    },
    async getval() {
      await this.getLocation();
    },
  },
  created() {
    this.getval();
  },
};
</script>
