<template>
  <v-app>
    <div>
      <div
        class="header-gradient py-10 px-5"
        :class="{ 'px-16 ': $vuetify.breakpoint.smAndUp }"
      >
        <h1 class="white--text">Privacy Policy</h1>
      </div>
      <div
        class="px-5 pt-10 text-justify"
        :class="{ 'px-16 ': $vuetify.breakpoint.smAndUp }"
      >
        <h1 class="title">Introduction</h1>
        <h1 class="title">Welcome to our Housefinder Technologies LTD</h1>
        <p class="mt-5">
          Housefinder Technologies LTD respects your privacy and is committed to protecting your personal data. 
          This privacy policy will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.
          This privacy policy aims to give you information on Housefinder Technologies LTD collects and processes your personal data through your use of this website.
          It is important that you read this privacy policy together with any other privacy policy or fair processing notice we may provide on specific 
          occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements the other policies and notices and is not intended to override them.
        </p>
        <h1 class="title">Controller</h1>
        <p>
          Housefinder Technologies LTD is the controller and responsible for your personal data.<br>
          You can visit our office or call for any questions or request in relation to this privacy policy. <br>
          It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
        </p>
        <h1 class="title">Third-party marketing</h1>
        <p>
          We will not share your personal data with any company outside Housefinder Technologies LTD for marketing purposes.
        </p>
        <h1 class="title">The data we collect about you</h1>
        <p>
          Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). <br>
          We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:
        </p>
        <div class="ml-5" :class="{ 'ml-8': $vuetify.breakpoint.smAndUp }">
          <p>
            <span class="mr-3">•</span>Identity Data includes full name, username or similar identifier, marital status, title, date of birth and gender.
          </p>
          <p>
            <span class="mr-3">•</span>Contact Data includes address, email address and telephone numbers.
          </p>
          <p>
            <span class="mr-3">•</span>Financial Data includes bank account and payment card details.
          </p>
          <p>
            <span class="mr-3">•</span>Transaction Data includes details about payments to and from you and other details of services you have purchased from us.
          </p>
          <p>
            <span class="mr-3">•</span>Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.
          </p>
          <p>
            <span class="mr-3">•</span>Profile Data includes your username and password, orders made by you, your interests, preferences
          </p>
          <p>
            <span class="mr-3">•</span>Usage Data includes information about how you use our website and services.
          </p>
          <p>
            <span class="mr-3">•</span>Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.
          </p>
        </div>
        <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. 
          For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.
        </p>
        <h1 class="title">How we use your personal data</h1>
        <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
        <div class="ml-5" :class="{ 'ml-8': $vuetify.breakpoint.smAndUp }">
          <p>
            <span class="mr-3">•</span>Where we need to perform the contract, we are about to enter into or have entered into with you.
          </p>
          <p>
            <span class="mr-3">•</span>Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests. Legitimate interest means the interest of our business in conducting and managing our business to enable us to give you the best service and the best and most secure experience. We do not use your personal data for activities outside our contract with you or provide it to a third party.
          </p>
          <p>
            <span class="mr-3">•</span>Where it is necessary for us to comply with legal and regulatory requirements.
          </p>
        </div>
        <p>Generally we do not rely on consent as a legal basis for processing your personal data other than in relation to sending direct marketing communications to you via telephone, post, email or other electronic means. You have the right to withdraw consent to marketing at any time by contacting us.</p>
        <h1 class="title">Purposes for which we will use your personal data</h1>
        <div class="ml-5" :class="{ 'ml-8': $vuetify.breakpoint.smAndUp }">
          <p>
            <span class="mr-3">•</span>To register you as a new customer.
          </p>
          <p>
            <span class="mr-3">•</span>Performance of a contract with you
          </p>
        </div>
      </div>
      
    </div>
  </v-app>
</template>
<script>
export default {
  metaInfo() {
		return {
			title: `Privacy Policy - Housefinder.com.ng`,
			
		}
	},

};
</script>
<style scoped>
div {
  color: #4a425f;
}
div div .header-gradient {
  background: linear-gradient(91.83deg, #4a425f 0.62%, #896d19 100%);
}
</style>
