<template>
  <v-app>
    <div>
      <v-row class="px-5 pt-10 text-justify" :class="{ 'px-16 ': $vuetify.breakpoint.smAndUp }">
        <v-col class="d-flex flex-col justify-center items-center">
          <div class="px-5 pt-10 float text-justify">
            <h1 class="">Not Found</h1>
            <p class="mt-4">We couldn't find the page you are looking for</p>
            <v-btn style="font-size: 15px" dark depressed color="#4A425F" height="50" ><router-link class="white--text text-decoration-none" :to="`/`">GO TO HOMEPAGE</router-link></v-btn>
          </div>
        </v-col>
        <v-col>
          <div >
<!--            <v-col><v-img :aspect-ratio="16/10" :src="require('../assets/images/404-1.png')"/></v-col>-->
            <v-col><v-img max-width="900" max-height="900" :src="require('../../assets/404.jpg')"/></v-col>
          </div>

        </v-col>


      </v-row>

    </div>
  </v-app>
</template>
<script>
export default {
  metaInfo() {
		return {
			title: `Not Found| Housefinder.com.ng`,
			
		}
	},
};
</script>
<style scoped>

</style>
