<template>
  <div
    class="overflow-hidden hidden-md-and-up"
    height="80"
  
    >
      <div>
          <v-btn
              class="mb-8"
              color="grey"
              outlined
              
              @click.stop="drawer = !drawer"
              small
          >
              <v-icon>mdi-filter-outline</v-icon>filter
          </v-btn>
      </div>

      <v-navigation-drawer
      fixed
      class="hidden-md-and-up"
      v-model="drawer"
      >
      <template>
          <v-card class="mx-auto elevation-0" width="300">
              <agents-filter/>
          </v-card>
      </template>

      </v-navigation-drawer>
    </div>
</template>

<script>
import AgentsFilter from "../layout/AgentsFilter.vue";
export default {
    components: { AgentsFilter },
    data: () => ({
      drawer: false,
    }),

    watch: {
      group () {
        this.drawer = false
      },
    },
  }
</script>
