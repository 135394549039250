<template>
  <div>
    <v-container fluid class="">
      <!-- <h1 class="maxWidth">Find Future Space at a Suitable Budget</h1> -->
      <v-container v-if="loading" class="mx-auto mt-8" max-width="300">
        <skeleton-loader />
      </v-container>
      <v-row>
        <v-col cols="12" sm="10" md="8">
          <v-sheet flat class="py-4 px-1 grey lighten-3">
            <v-chip-group
              mandatory
              class="grey lighten-3"
              active-class="blue white--text"
            >
              <v-chip
                color="white"
                v-for="category in categories"
                :key="category.id"
                @click="switchCat(category.id)"
              >
                {{ category.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <div v-if="filteredCategory.length > 0">
        <!-- sm and down slider control -->
        <v-row
          class="px-16 mb-n9 mt-n6 hidden-md-and-up"
          v-show="filteredCategory[0].products.length > 1"
        >
          <v-col>
            <v-btn icon @click="scrollRight(currentCategoryId)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn icon @click="scrollLeft(currentCategoryId)">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!--md and up slider control -->
        <v-row
          class="px-16 mb-n9 mt-n6 hidden-sm-and-down"
          v-show="filteredCategory[0].products.length > 3"
        >
          <v-col>
            <v-btn icon @click="scrollRight(currentCategoryId)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn icon @click="scrollLeft(currentCategoryId)">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <div v-if="filteredCategory[0]">
          <div>
            <div
              class="testimonial-container py-8 ml-n4"
              :class="{
                'px-2': $vuetify.breakpoint.smAndDown,
              }"
              :id="`catSlider${currentCategoryId}`"
            >
              <v-card
                color="grey lighten-5"
                class="testimonial-card pa-2 "
                v-for="product in filteredCategory[0].products"
                :key="product.id"
              >
                <router-link
                  class="text-decoration-none black--text"
                  :to="{ path: `/product-details/${product.slug}` }"
                >
                  <v-img
                    height="200px"
                    :src="
                      product.product_images.length > 0
                        ? product.product_images[0].full
                        : ''
                    "
                  >
                  </v-img>
                  <div class="mt-4 ">
                    <div class="cyan--text text--darken-4 font-weight-bold">
                      <div>
                        ₦ {{ product.converted_price }}
                        <span
                          v-show="product.duration"
                          class="text-caption grey--text"
                        >
                          {{ `(${product.duration})` }}
                        </span>
                      </div>
                      <span
                        class="mt-n6 px-1 text-caption font-weight-normal float-right blueCat darken-3 white--text"
                      >
                        {{ product.product_type }}
                      </span>
                    </div>
                  </div>
                  <v-divider class="divider-color mb-n2" width="50"></v-divider>
                  <h4
                    class="mt-4 grey--text text--darken-3"
                    style="height: 45px"
                  >
                    {{ product.title | truncate(63) }}
                  </h4>
                  <div
                    class=" text-caption grey--text text-lighten-2 mb-n6 mt-2"
                  >
                    <v-icon small>mdi-map-marker</v-icon
                    >{{ `${product.state.name}, ${product.lga.name}` }}
                  </div>
                  <div class="mt-8 mb-n2">
                    <span class="text-caption">{{ product.listed_in }}</span>
                    <v-icon v-show="product.bathroom" class="ml-4" small>
                      mdi-shower
                    </v-icon>
                    <span class="text-caption ml-1">{{
                      product.bathroom
                    }}</span>
                    <v-icon v-show="product.bedroom" class="ml-4" small>
                      mdi-bed
                    </v-icon>
                    <span class="text-caption ml-1">{{ product.bedroom }}</span>
                  </div>
                </router-link>
                <div class="mb-2 mt-4">
                  <router-link
                    class="mt-14 text-decoration-none black--text"
                    :to="{ path: `/agent/products/${product.user.id}` }"
                  >
                    <div class="text-subtitle-2 mt-8 text-capitalize ">
                      Agent:

                      <span class="lime--text text--darken-4 ml2">
                        {{
                          product.user.user_type == 'admin_can_add'
                          ? 'Housefinder'
                          :product.user.agent.title
                            ? product.user.agent.title
                            : product.user.full_name
                        }}
                      </span>
                      <v-icon
                        v-show="
                          product.user.verify && product.user.verify.status == 1
                        "
                        small
                        class="green--text"
                        >mdi-check-decagram</v-icon
                      >
                    </div>
                  </router-link>
                  <div
                    v-show="product.developer"
                    class=" text-caption text-capitalize "
                  >
                    developer:
                    <span class="grey--text text-lighten-2">
                      {{ product.developer }}</span
                    >
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import SkeletonLoader from "../layout/SkeletonLoader";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    SkeletonLoader,
  },
  data() {
    return {
      loading: false,
      categories: [],
      currentCategoryId: "",
    };
  },
  computed: {
    ...mapState(["categoriesData"]),
    filteredCategory() {
      return this.categories.filter(c => c.id == this.currentCategoryId);
    },
  },
  methods: {
    ...mapActions(["getHomeData"]),
    changeStyle() {
      if (this.mode === "dark") {
        this.mode = "light";
      } else {
        this.mode = "dark";
      }
    },
    scrollLeft(id) {
      var elmnt = document.getElementById("catSlider" + id);
      elmnt.scrollLeft += 345;
    },
    scrollRight(id) {
      var elmnt = document.getElementById("catSlider" + id);
      elmnt.scrollLeft -= 345;
    },
    async getData() {
      this.loading = true;
      await this.getHomeData();

      this.categories = this.categoriesData;
      this.currentCategoryId = this.categories[0].id;
      this.loading = false;
    },
    switchCat(catId) {
      this.currentCategoryId = catId;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.blueCat {
  background: #0062df;
}
.divider-color {
  background: #896d19;
}
.know-more {
  background: #896d19;
}

.section {
  /* I added this just for fun */
  background-color: skyblue;
  /* The code that you need to copy */
  margin: 0px !important;
}
.img-container {
  height: 450px;
  width: 450px;
  border-radius: 100%;
  background: #fff;
}
.testimonial-container {
  display: flex;
  flex-flow: row;
  min-height: 392px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-x: scroll !important;
  .testimonial-card {
    flex: 0 0 auto;

    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.25) !important;
    margin-left: 2.5%;
    width: 320px;
    border-radius: 5px;
    .testimonial-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #6c6868;
    }
    .testimonial-name {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 28px;

      color: #454343;
    }
  }
}

@media only screen and (max-width: 600px) {
  .heroimg {
    width: 100% !important;
    height: 400px;
  }
  .img-container {
    width: 300px;
    height: 300px;
    img {
      width: 100%;
    }
  }
  .testimonial-card {
    width: 400px !important;
  }
}
</style>
