<template>
  <v-app class="grey lighten-3 maxWidth" :class="{ 'px-12 py-10': $vuetify.breakpoint.smAndUp,  }">
        <div class="white ">
        <v-container class="">
            <h1 class="mb-5 title">All Property Requests</h1>
            <div class="">
                <div class="hidden-md-and-up">
                    <v-btn
                        class="mb-8"
                        color="grey"
                        outlined
                        @click="toggleFilter = !toggleFilter"
                        small
                    >
                        <v-icon>mdi-filter-outline</v-icon>filter
                    </v-btn>
                </div>
                <div :class="{ 'hidden-sm-and-down': toggleFilter }">
                    <div>
                        <v-row>
                            <v-col cols="12" sm="2">
                                <v-select
                                    class=""
                                    label="State"
                                    :items="states"
                                    item-text="name"
                                    item-value="id"
                                    v-model="state"
                                    background-color="#F0F0F0"
                                    solo
                                    flat
                                    v-on:change="onChange"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                :class="{ 'mt-n7': $vuetify.breakpoint.xs }"
                                cols="12"
                                sm="3"
                            >
                                <v-select
                                    background-color="#F0F0F0"
                                    :items="lgas"
                                    item-text="name"
                                    item-value="id"
                                    v-model="lga"
                                    label="Locality"
                                    solo
                                    flat
                                ></v-select>
                            </v-col>
                            <v-col
                            :class="{ 'mt-n7': $vuetify.breakpoint.xs }"
                            cols="12"
                            sm="2"
                            >
                                <v-select
                                    background-color="#F0F0F0"
                                    solo
                                    flat
                                    :items="categories"
                                    label="Categories"
                                    v-model="cat"
                                    item-text="name"
                                    item-value="slug"
                                ></v-select>
                            </v-col>
                            <v-col
                            :class="{ 'mt-n7': $vuetify.breakpoint.xs }"
                            cols="12"
                            sm="3"
                            >
                            <v-select
                                style=""
                                background-color="#F0F0F0"
                                :items="propertytype"
                                item-text="name"
                                item-value="value"
                                v-model="property_type"
                                label="Property Type"
                                solo
                                flat
                            >
                            </v-select>
                            </v-col>
                            <v-col
                            :class="{ 'mt-n7': $vuetify.breakpoint.xs }"
                            cols="12"
                            sm="2"
                            >
                            <v-btn
                                v-show="filterLoading == false"
                                id="addlist-button"
                                class=" px-md-6 py-md-6 text-capitalize white--text"
                                color="#0062df"
                                @click="submit"
                            >
                                Filter
                            </v-btn>
                            <v-progress-circular
                                v-show="filterLoading"
                                :width="3"
                                class="ml-4 px-6 py-6"
                                color="green"
                                indeterminate
                            >
                            </v-progress-circular>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            
            <div v-if="skeloader || processedRequests == false">
					<skeleton-loader/>
				</div>
            <div v-if="skeloader == false">
                <v-row class="" >
                    <v-col 
                        cols="12" 
                        sm="6" 
                        md="3"
                        v-for="request in processedRequests" 
                        :key="request.id"
                    >
                        <v-card
                            
                            outlined
                            class="mt-4"
                            
                            >
                                <v-row class="ma-2" >
                                    <v-col cols="6">
                                        <h4 class="text--primary">Property Type</h4>
                                        <p class="text-subtitle-2 text--primary">{{request.property_type}}</p>
                                    </v-col>
                                    <v-col cols="6">
                                        <h4 class="text--primary">Category</h4>
                                        <p class="text-subtitle-2 text--primary"> {{request.category.name}}</p>
                                    </v-col>
                                    <v-col cols="6" class="mt-n8">
                                        <h4 class="text--primary">Bedroom</h4>
                                        <p class="text-subtitle-2 text--primary"> {{request.bedroom}}</p>
                                    </v-col>
                                    <v-col cols="6" class="mt-n8">
                                        <h4 class="text--primary">Budget</h4>
                                        <p class="text-subtitle-2 text--primary">#{{request.budget}}</p>
                                    </v-col>
                                    <v-col cols="6" class="mt-n8">
                                        <h4 class="text--primary">State</h4>
                                        <p class="text-subtitle-2 text--primary">{{request.state.name}}</p>
                                    </v-col>
                                    <v-col cols="6" class="mt-n8">
                                        <h4 class="text--primary">City</h4>
                                        <p class="text-subtitle-2 text--primary">{{request.lga.name}}</p>
                                    </v-col>
                                    <v-col cols="6" class="mt-n8">
                                        <h4 class="text--primary">Date</h4>
                                        <p class="text-subtitle-2 text--primary">{{request.post_date}}</p>
                                    </v-col>
                                     <v-col cols="6" class="mt-n8">
                                        <v-btn 
                                            small 
                                            class="float-right mt-4"
                                            v-show="
                                                userType == 'admin' ||
                                                userType == 'editor' ||
                                                userType == 'super_admin' ||
                                                userType == 'admin_can_add'
                                            "                                                
                                        >
                                            <v-icon @click="RemoveRequest(request.id)"  class="red--text ">mdi-delete</v-icon>
                                        </v-btn>
                                        <v-dialog 
                                            v-model="dialogDelete" 
                                            max-width="500px"
                                            persistent 
                                            :retain-focus="false"
                                        >
                                            <v-card>
                                                <v-card-title class="text-h5">Are you sure you want to delete this Request?</v-card-title>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn class="white--text" color="grey darken-3"  @click="closeDelete">Cancel</v-btn>
                                                    <v-btn v-show="loading2 == false"  class="white--text" color="red lighten-2"  @click="deleteItemConfirm">OK</v-btn>
                                                    <v-progress-circular
                                                        v-show="loading2"
                                                        :width="3"
                                                        class="ml-4"
                                                        color="green"
                                                        indeterminate
                                                    >
                                                    </v-progress-circular>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                
                                </v-row>
                                
                         
                            <v-expand-transition class="mt-n6">
                                <div v-show="showId.indexOf(request.id) !== -1">
                                    <v-divider></v-divider>

                                    <div class="px-3">
                                        <h3 class="mt-2">Description</h3>
                                        <div class="mb-2">
                                            {{request.description}}
                                        </div>
                                        <div><strong>Phone:</strong>{{request.phone}}</div>
                                        <div><strong>Email:</strong>{{request.email}}</div>
                                        <div><strong>Locality:</strong>{{request.area}}</div>
                                    </div>
                                </div>
                                </v-expand-transition>
                                <v-card-actions>
                                <v-btn
                                    
                                    class="mb-2"
                                    block
                                    depressed
                                    @click="show(request.id)"
                                    dark
                                    color="#0062DF"
                                >
                                    {{showId.indexOf(request.id) !== -1 ? 'Show Less': 'More Info'}}
                                    <v-icon>{{ showId.indexOf(request.id) !== -1? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <div class="mt-2">
                    <RequestPageControl/>
                </div>
            </div>
        </v-container>
        <v-container v-if="requestIsEmpty">
            <v-sheet
            class="pa-12 mb-10"
            color="grey lighten-3"
            >
            <v-sheet
                
                class="mx-auto text-center"
                
                color="grey lighten-3"
            ><h3 >
                Sorry No Request Found
            </h3></v-sheet>
            
            
            </v-sheet>
        </v-container>
    </div>
  </v-app>
</template>
<script>
import RequestPageControl from "../layout/RequestPageControl.vue";
import SkeletonLoader from "../layout/SkeletonLoader";
import {mapActions  } from "vuex";
import {mapGetters  } from "vuex";
import {mapMutations  } from "vuex";
import {mapState  } from "vuex";
export default {
    components: {
        RequestPageControl,
        SkeletonLoader 
	},

    data: () => ({
        showId: [],
        skeloader: false,
        loading: false,
        toggleFilter: true,
        filterLoading: false,
        state: "",
        lga: "",
        property_type: "",
        cat: "",
        dialogDelete: false,
        RequestId: '',
        loading2: false,
        userType: null,
        propertytype: [
            { name: "For Sale", value: "Sale" },
            { name: "For Rent", value: "Rent" },
            { name: "For Short Let", value: "For Short Let" },
        ],
    }),
    computed: {
        ...mapState({requestIsEmpty: state => state.request.requestIsEmpty}),
        ...mapGetters({processedRequests: "request/processedRequests"}),
        ...mapGetters(["categories", "states", "lgas"]),
    },
    methods: {
        ...mapActions({getRequestList: "request/getRequestList", 
            setSearch: "request/setSearch", deleteRequest: "request/deleteRequest"}),
        ...mapActions(["getLga", "getLocation"]),
        ...mapMutations({
            _setState: "request/_setState",
            _setLga: "request/_setLga",
            _setPropertyType: "request/_setPropertyType",
            _setCurrentCategory: "request/_setCurrentCategory",
            _setSkeloader: "request/_setSkeloader",
            _setSearch: "request/setSearch",
        }),
        async submit() {

            this._setState(this.state);
            this._setLga(this.lga);
            this._setPropertyType(this.property_type);
            this._setCurrentCategory(this.cat);
            this._setSkeloader(true);
            this.filterLoading = true;
            this.skeloader = true;
            await this.setSearch();
            this.skeloader = false;
            this.filterLoading = false;
            this._setSkeloader(false);
        },
        show(id){
            let index = this.showId.indexOf(id);
            if(index === -1){
                this.showId.push(id);
            }
            else{
                this.showId.splice(index,1);  
            }
        },
        onChange(event) {
            this.getLga(event);
        },
        async loadData(){
            this.skeloader = true;
            await this.getRequestList();
            this.skeloader = false;
        },
        async getStates() {
            await this.getLocation();
        },
        RemoveRequest(id) {
            this.RequestId = id;
            this.dialogDelete = true
        },
        async deleteItemConfirm(){
            this.loading2 = true;
            let response = await this.deleteRequest(this.RequestId);
            if(response.success){
                    this.$toastr.s("Request Deleted Successfully");
                    this.loadData()
                    //this.loading2 = false;
           }
           else{
                this.$toastr.e(response);
                this.loading2 = false;
           }
            this.closeDelete()
        },
        closeDelete () {
            this.dialogDelete = false
        },
    },
    watch: { 
        dialogDelete (val) {
            val || this.closeDelete()
        }, 
	},
    created(){
        this.userType = localStorage.getItem("userType");
        this.loadData();
        this.getStates();
    },
    metaInfo() {
        return {
            title: `Request Property| Housefinder.com.ng`,
        }
    },
};
</script>
<style scoped>

</style>
