<template>
  <v-app>
    <DesktopNav/>
    <MobileNav/>

    <!-- Sizes your content based upon application components -->
    <div>

      <!-- Provides the application the proper gutter -->
      <!-- <v-container fluid> -->
        <!-- If using vue-router -->
        <router-view></router-view>
      <!-- </v-container> -->
    </div>
    <div class="mt-10" v-show="toggleLayout">
      <FooterDesktop/>
    </div>
  </v-app>
</template>

<script>
import {mapActions} from "vuex";
import DesktopNav from './components/layout/DesktopNav';
import MobileNav from './components/layout/MobileNav';
import FooterDesktop from './components/layout/FooterDesktop';

export default {
  name: 'App',
  metaInfo() {
		return {
			title: "Real Estate, Property and Homes in Nigeria for Sale, Rent and Short let | Housefinder.com.ng",
			meta: [
				{
					vmid: "description",
					name: "description",
					content:`The top Nigerian real estate and property website with property in 
						Nigeria for sale, rent, lease short let, We offer Nigerian property seekers 
						an easy way to find details of property in Nigeria including homes, houses, 
						lands, shops, office spaces and other commercial properties to buy or rent 
						Thousands of property listings available`
				}
			]
		}
	},

  components: {
    DesktopNav,
    MobileNav,
    FooterDesktop,
  },

  data: () => ({
    toggleLayout: true,
  }),
  methods: {
    ...mapActions(["getCategories"]),
  },
  watch: { 
    $route() { 
			if(this.$router.history.current.path == '/user/chats' || this.$router.history.current.path == '/mobile/chat'){
				this.toggleLayout = false;
			}
      else{
        this.toggleLayout = true;
      } 
		} 
	},
  created(){
    if(this.$router.history.current.path == '/user/chats' || this.$router.history.current.path == '/mobile/chat') {
      this.toggleLayout = false;
    }
    this.getCategories()
  }
};
</script>
