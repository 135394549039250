var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"fix-top hidden-sm-and-down headerColor py-3"},[_c('v-container',{staticClass:"header-1 hidden-sm-and-down",attrs:{"elevation-0":""}},[_c('router-link',{staticClass:"trans-logo link",attrs:{"to":{ path: "/" }}},[_c('img',{attrs:{"height":"30px","src":require("../../assets/images/housefinderLogo.png")}})]),_c('nav',[_c('ul',{staticClass:"menu-items"},[_c('li',{staticClass:"link"},[_c('router-link',{attrs:{"to":{ path: "/" }}},[_vm._v("Home")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('li',_vm._g(_vm._b({staticClass:"link"},'li',attrs,false),on),[_vm._v(" Buy/Rent ")])]}}])},[_c('v-list',{staticClass:"mt-8"},_vm._l((_vm.categories),function(item,index){return _c('v-list-item',{key:index,staticClass:"mt-n4 "},[_c('router-link',{staticClass:"black--text link",attrs:{"to":{ path: ("/properties/" + (item.slug)) }}},[_vm._v(_vm._s(item.name))])],1)}),1)],1),_c('li',{staticClass:"link"},[_c('router-link',{staticClass:"black--text link",attrs:{"to":{ path: "/product/create" }}},[_vm._v("Sell")])],1),_c('li',{staticClass:"link"},[_c('router-link',{staticClass:"black--text link",attrs:{"to":{ path: "/agents" }}},[_vm._v("Our Agents")])],1),_c('v-menu',{staticClass:"requestsZindex",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('li',_vm._g(_vm._b({},'li',attrs,false),on),[_vm._v(" Request Property ")])]}}])},[_c('v-list',{staticClass:"mt-3"},_vm._l((_vm.requests),function(item,index){return _c('v-list-item',{key:index},[_c('router-link',{staticClass:"black--text link",attrs:{"to":{ path: ("/requests/" + (item.slug)) }}},[_vm._v(_vm._s(item.name))])],1)}),1)],1),_c('router-link',{staticClass:" link",attrs:{"to":{ path: "/login" }}},[(_vm.login != 'true')?_c('v-btn',{staticClass:"ml-5 px-6 font-weight-bold hidden-sm-and-down",staticStyle:{"font-size":"15px","background-color":"#cce2ff"},attrs:{"text":"","color":"#0062DF"}},[_vm._v(" Login ")]):_vm._e()],1),_c('router-link',{staticClass:"white--text link",attrs:{"to":{ path: "/register" }}},[(_vm.login != 'true')?_c('v-btn',{staticClass:"font-weight-bold ml-5 hidden-sm-and-down",staticStyle:{"font-size":"15px"},attrs:{"depressed":"","dark":"","color":"#0062DF"}},[_vm._v(" Sign Up ")]):_vm._e()],1),(_vm.login == 'true')?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"justify-center",attrs:{"depressed":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"indigo","size":"30px"}},[_vm._v(" mdi-account ")])],1)]}}],null,false,1198876785)},[_c('v-list',{staticClass:"mt-8"},[_c('v-list-item',{staticClass:"mt-4 "},[_c('router-link',{staticClass:"grey--text link ",attrs:{"to":{ path: "/dashboard" }}},[_vm._v("Dashboard")])],1),_c('v-list-item',{staticClass:"mt-n4 "},[_c('router-link',{staticClass:"grey--text link",attrs:{"to":{ path: "/user/profile" }}},[_vm._v("Profile")])],1),_c('v-list-item',{staticClass:"mt-n4 "},[_c('a',{staticClass:"grey--text link",on:{"click":_vm.userLogout}},[_vm._v("Logout")])])],1)],1):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }