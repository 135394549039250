import customAxios from '../helpers/AxiosInstance';
import * as url from '../store/AuthStoreRoute';

export default {
        
    state: {
        authenticated: false,
        jwt: null,
        authUser:{},
        users: [],
        userFriends: [],
        userProducts: [],
        userNotify: [],
        editedUser: {},
        messages: [],
        unreadMessage: '',
        isLogin: 'false',
        verifyUser: [],
        packageData: [],
       singleVerifyUser : {},
        },
    getters: {
        authenticatedAxios() {
            let token = localStorage.getItem('token');
            let bearer = {"Authorization": `Bearer ${token}`}
            return bearer;
        },
        //getToken(){
            //let token = localStorage.getItem('token');
            //return token;
        //}
    },
    mutations: {
        updateMessage(state, data){
            state.messages[state.messages.length - 1].id = data.ndata;
        },
        setUserNotify(state, data){
            state.userNotify = data.ndata;
        },
        setFriends(state, data){
            state.userFriends = data.fdata;
        },
        setMessage(state, data){
            state.messages = data.mdata;
        },
        setUnreadMessage(state, data){
            state.unreadMessage = data.rdata;
        },
        setEditUser(state, data){
            state.editedUser = data.udata;
        },
        setLogin(state, data){
            state.isLogin = data;
        },
        setAuthenticated(state, data) {
            localStorage.setItem('authUser',JSON.stringify(data.user));
            //localStorage.setItem('token',data.token);
            localStorage.setItem('userType',data.user.user_type);
            state.authenticated = true;
            state.authUser = data.user;
        },
        _setUsers(state, data) {
            state.users = data.users;
        },
        clearAuthentication(state) {
            state.authenticated = false;
            state.jwt = null;
        },
        _updateUser(state, data) {
            localStorage.setItem('authUser',JSON.stringify(data.user));
        },
        removeUser(state, id){
            let index = state.users.findIndex(u => u.id == id);
            state.users.splice(index, 1);
        },
        removeNotify(state, id){
            let index = state.userNotify.notify.findIndex(p => p.id == id);
            state.userNotify.notify.splice(index, 1);
        },
        _removeAllNotify(state){  
            state.userNotify.notify = [];
            state.userNotify.unread_notify = 0;
        },
        pushToChats(state, content) {
            state.messages.push({
                id: null,
                message: content,
                read_at: null,
                sent_at: "Just Now",
                type: 0,
               
            });
        },
        setVerifyUserList(state, user){
            state.verifyUser = user;
        },
        setSingleVerifyUser(state, user){
            state.singleVerifyUser = user;
        },
        setPackage(state, data){
            state.packageData = data;
        },
        _updateUserList(state, data){
            let index = state.verifyUser.findIndex(u => u.id == data.id);
            state.verifyUser[index].status = data.status;
        },
    },
    actions: {
        async login(context, credentials) {
            let response = await customAxios.post(url.loginUrl, credentials);
            if (response.data.success == true) {
                context.commit("setLogin", 'true');
                let user = response.data.data.user;
                let token = response.data.data.token;
                let bearer = {"Authorization": `Bearer ${token}`}
                localStorage.setItem('bearer',bearer);
                localStorage.setItem('token',token);
                context.commit("setAuthenticated", {user});
                return response.data.success;
            }
            
        },
        async logout(context) {
            await context.commit("setLogin", 'false');
            localStorage.removeItem('authUser');
            localStorage.removeItem('token');
            localStorage.removeItem('userType');
        },
        async register(context, credentials) {
            let response = await customAxios.post(url.registerUrl, credentials);
            if (response.data.success == true) {
                context.commit("setLogin", 'true');
                let user = response.data.data.user;
                let token = response.data.data.token;
                let bearer = {"Authorization": `Bearer ${token}`}
                localStorage.setItem('bearer',bearer);
                localStorage.setItem('token',token);
                context.commit("setAuthenticated", {user});
            }
            return response
        },
        async getUsers(context) {
            let users = (await customAxios.get(url.usersUrl)).data.data;
            if (users) {
                context.commit("_setUsers", {users});
            }
        },
        async updateUser(context, user) {
            let response = await customAxios.put(url.usersUrl+'/'+user.id, user);
            if (response.data.success == true) {
                context.commit("_updateUser", {user});
            }
            return response
        },
        async adminUpdateUser(context, user) {

            let response = await customAxios.post(url.adminProfileUpdateUrl, user);
            //if (response.data.success == true) {
                //context.commit("_updateUser", {user});
            //}
            return response
        },

        async updateUserType(context, user) {
            let response = await customAxios.post(url.userTypeUrl, user);
            //if (response.data.success == true) {
                //context.commit("_updateUser", {user});
            //}
            return response
        },
        async resetPassword(context, password) {
            let response = await customAxios.post(url.passwordUpdateUrl, password);
            return response
        },
        async deleteUser(context, id) {
            let data = (await customAxios.delete(url.usersUrl+'/'+id)).data;
            if (data.success == true){
            context.commit("removeUser",id);
            }
            return data;
        },
        async findUser(context, id) {
            let udata = (await customAxios.get(url.usersUrl+'/'+id)).data.data;
            //context.commit("setEditUser", {udata}); 
            return udata;  
        },
        async getUserNotify(context) {
            let ndata = (await customAxios.get(url.getUserNotify)).data.data;
            context.commit("setUserNotify", {ndata});   
        },
        async markNotify(context, id) {
            let data = {notify_id: id};
            (await customAxios.post(url.editNotify, data)).data;
    
            //context.commit("setUserNotify", {ndata});   
        },
        deleteNotify(context, id) {
            let data = {notify_id: id}
            customAxios.post(url.deleteNotify, data);
            context.commit("removeNotify",id);   
        },
        async deleteAllNotify(context) {
            let res = (await customAxios.post(url.deleteAllNotify)).data;
            console.log(res)
            context.commit("_removeAllNotify"); 
            return res;
             
        },
        async getFriends(context) {
            let fdata = (await customAxios.get(url.getFriends)).data.data;
            context.commit("setFriends", {fdata});  
        },
        async getMessage(context, sessionId) {
            let mdata = (await customAxios.get(url.getMessage+'/'+sessionId)).data.data;
            context.commit("setMessage", {mdata});   
        },
        async readMessage(context, sessionId) {
            (await customAxios.get(url.readMessage+'/'+sessionId)).data.data;  
        },
        async sendMessage(context, data) {
            let mdata = (await customAxios.post(url.sendMessage+'/'+data.sessionId, data)).data;
            context.commit("updateMessage", {mdata})    
        },
        async clearMessage(context, sessionId) {
            let mdata = [];
            context.commit("setMessage", {mdata}); 
            (await customAxios.get(url.clearMessage+'/'+sessionId)).data.data;     
        },
        async unreadMessageCount(context) {
            let rdata = (await customAxios.get(url.unreadMessage)).data.data;
            context.commit("setUnreadMessage", {rdata});    
        },
        async createChatSession(context, friendId) {
            let data = (await customAxios.post(url.createChatSession, friendId)).data;
            return  data.data.session_id;   
        },
        async sendChat(context, data) {
            (await customAxios.post(url.sendMessage+'/'+data.sessionId, data)).data;
               
        },
        async productImageDelete(context, data) {
            let response = (await customAxios.post(url.imageDeleteUrl, data)).data;
            return response
        },
        async sendContactMail(context, data) {
            let response = (await customAxios.post(url.contactMailUrl, data)).data;
            return response
        },
        async forgotPassword(context, data) {
            let response = (await customAxios.post(url.forgotPasswordUrl, data)).data;
            return response
        },
        async changePassword(context, data) {
            let response = (await customAxios.post(url.resetPasswordUrl, data)).data;
            return response
        },
        async verifyUserList(context) {
            let response =  (await customAxios.get(url.verificationUrl)).data;
            context.commit("setVerifyUserList", response.data); 
        },
        async requestVerification(context,data) {
            let response =  (await customAxios.post(url.verificationUrl, data)).data;
            return response;
        },
        async updateVerify(context,data) {
            let response =  (await customAxios.post(url.verifyUpdateUrl, data)).data;
            context.commit("_updateUserList", data); 
            return response.success;
        },
        async getSingleVerifyUser(context) {
            let response =  (await customAxios.get(url.verifyUserUrl)).data;
            context.commit("setSingleVerifyUser", response.data); 
        },
        async addPackage(context, data) {
            let response = await customAxios.post(url.packages, data);
            return response
        },
        async getPackage(context) {
            let response = (await customAxios.get(url.packages)).data;
            context.commit("setPackage", response.data); 
            
        },
        async deletePackage(context, id) {
            let cdata = (await customAxios.delete(url.packages+'/'+id)).data;
            if (cdata.success == true){
            //context.commit("removeCategory",id);
            }
            return cdata;
        },

        async updatePackage(context, formData) {
            let cdata = (await customAxios.put(url.packages+'/'+formData.id, formData)).data;
            return cdata;
        },

        async checkout(context, packageId) {
            let response = (await customAxios.post(url.checkout,packageId)).data;
            console.log(response)
            return response
        },

        async updateOrder(context, payload) {
            console.log(payload);
            let paymentDetails = {
                reference: payload.reference,
                order_number: localStorage.getItem('order_number')
            };
            let response = (await customAxios.post(url.handlePackage,paymentDetails)).data;
            console.log(response)
            localStorage.removeItem('order_number');
        },
        async userTransaction() {
            let response = (await customAxios.get(url.userTransaction)).data;
            console.log(response.data)
            return response.data;
        },
    }
}
