export const loginUrl = `/api/login`;
export const registerUrl = `/api/register`;
export const profileUpdateUrl = `/api/user/profile/update`;
export const adminProfileUpdateUrl = `/api/user/admin/profile/update`;
export const passwordUpdateUrl = `/api/password-update`;
export const usersUrl = `/api/user`;
export const getUserNotify = `/api/user-notify`;
export const getFriends = `/api/get-friends`;
export const getMessage = `/api/session/chats`;
export const readMessage = `/api/session/read`;
export const sendMessage = `/api/chat/send`;
export const clearMessage = `/api/session/clear`;
export const unreadMessage = `/api/session/unread-count`;
export const editNotify = `/api/edit/notify`;
export const deleteNotify = `/api/delete/notify`;
export const deleteAllNotify = `/api/delete/all/notify`;
export const createChatSession = `/api/session/create`;
export const imageDeleteUrl = `/api/admin/product/image/delete`;
export const userTypeUrl = `/api/user/auth/role`;
export const contactMailUrl = `/api/contact/send`;
export const forgotPasswordUrl = `/api/forgot-password`;
export const resetPasswordUrl = `/api/reset/password`;
export const verificationUrl = `/api/auth/verification`;
export const verifyUpdateUrl = `/api/auth/verify-update`;
export const verifyUserUrl = `/api/auth/verify-user`;
export const  packages = `/api/admin/packages`;
export const  checkout = `/api/checkout`;
export const  handlePackage = `/api/handle-payment`;
export const userTransaction = `/api/user/auth/user-transaction`