export const countriesUrl = `/api/get-nationalities`;
export const stateUrl = `/api/get-states`;
export const lgaUrl = `/api/get-lga`;
export const productUrl = `/api/admin/product`;
export const publishUrl = `/api/admin/product/publish`;
export const featuredUrl = `/api/admin/product/featured`;
export const categoryUrl = `/api/admin/categories`;
export const paginatedProductUrl = `/api/paginated-product`;
export const categoryProductUrl = `/api/category-product`;
export const featuredProductUrl = `/api/featured-product`;
export const userProducts = `/api/user/auth/products`;
export const agentUrl = `/api/agents`;
export const agentProductUrl = `/api/agent/products`;
export const findUserProductUrl = `/api/user/auth/product`;