import Vue from 'vue';
import VueRouter from 'vue-router';
import multiguard from 'vue-router-multiguard';

import Home from "../components/site/Home";
import ProductList from "../components/site/ProductList";
import Agent from "../components/site/Agents";
import AgentProducts from "../components/site/AgentProducts";
import ProductDetails from "../components/site/ProductDetails";
import Login from "../components/layout/Login";
import Register from "../components/layout/Register";
import Contact from "../components/site/Contact";
import CreateRequest from "../components/site/CreateRequest.vue";
import Requests from "../components/site/Requests.vue";
import Terms from "../components/site/Terms";
import About from "../components/site/About";
import PrivacyPolicy from "../components/site/PrivacyPolicy";
import ForgotPassword from "../components/site/ForgotPassword";
import ResetPassword from "../components/site/ResetPassword";
import NotFound from "../components/site/NotFound";



const UserProfile = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/UserProfile");
const AddProperty  = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/AddProperty");
const PropertyList  = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/PropertyList");
const  ProductEditor  = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/ProductEditor");
const  AddCategory   = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/AddCategory");
const   CategoryList  = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/CategoryList");
const   Message  = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/Message");
const   UserList  = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/UserList");
const   MyProduct   = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/MyProduct");
const   AdminUserUpdate   = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/AdminUserUpdate");
const  MobileChat   = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/MobileChat");
const  Dashboard   = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/Dashboard");
const  RequestVerify   = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/RequestVerify");
const  VerifyUser   = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/VerifyUser");
const  ChangePassword  = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/ChangePassword");
const  AddPackage  = () =>
    import(/* webpackChunkName: "admin" */ "../components/admin/AddPackage");
const  PackageList  = () =>
    import(/* webpackChunkName: "admin" */ "../components/admin/PackageList");
const  PaystackForm  = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/PaystackForm");
const  UserTransaction  = () =>
    import(/* webpackChunkName: "admin" */ "../components/auth/UserTransaction");

Vue.use(VueRouter);

const isLogin = function(to, from, next) {
    let token = localStorage.getItem('token');
    if (token) {
        next();
    } 
    else {
        next("/login");
    }
}

const support_team = function(to, from, next) {
    let userType = localStorage.getItem('userType');
    if (userType == 'super_admin' || userType == 'editor') {
        next();
    }
}
const admin = function(to, from, next) {
    let userType = localStorage.getItem('userType');
    if (userType == 'super_admin' ||
        userType == 'admin' ||  
        userType == 'editor' ||
        userType == 'admin_can_add') {
        next();
    }
     
}


export default new VueRouter({
    mode: "history",
    routes: [
        {path: "/", component: Home},
        {path: "/properties/:slug", component: ProductList},
        {path: "/agents", component: Agent},
        {path: "/agent/products/:id", component: AgentProducts},
        {path: "/product/create", component: AddProperty, beforeEnter: multiguard([isLogin])},
        {path: "/admin/product/index", component:  PropertyList, beforeEnter: multiguard([isLogin, admin])},
        { path: "/product/edit/:slug", component: ProductEditor, beforeEnter: multiguard([isLogin])},
        { path: "/product-details/:slug", component: ProductDetails},
        { path: "/category/create", component: AddCategory, beforeEnter: multiguard([isLogin, support_team]) },
        { path: "/user/chats", component: Message,  beforeEnter: multiguard([isLogin]) },
        { path: "/user/transactions", component: UserTransaction,  beforeEnter: multiguard([isLogin]) },
        { path: "/user/my-products", component:  MyProduct,  beforeEnter: multiguard([isLogin])},
        { path: "/category/index", component: CategoryList,  beforeEnter: multiguard([isLogin, support_team])},
        { path: "/add-package", component: AddPackage,  beforeEnter: multiguard([isLogin, support_team])},
        { path: "/packages", component: PackageList,  beforeEnter: multiguard([isLogin, support_team])},
        { path: "/login", component: Login },
        { path: "/register", component: Register },
        { path: "/contact", component: Contact },
        { path: "/about", component: About },
        { path: "/requests", component: Requests, beforeEnter: multiguard([isLogin]) },
        { path: "/requests/create", component: CreateRequest, beforeEnter: multiguard([isLogin]) },
        { path: "/terms-and-conditions", component: Terms },
        { path: "/privacy-policy", component: PrivacyPolicy },
        { path: "/admin/users", component: UserList,  beforeEnter: multiguard([isLogin,admin])},
        { path: "/admin/users/:id", component: AdminUserUpdate,  beforeEnter: multiguard([isLogin,admin])},
        { path: "/user/profile", component: UserProfile, beforeEnter: multiguard([isLogin])},
        { path: "/mobile/chat", component: MobileChat, beforeEnter: multiguard([isLogin])},
        { path: "/dashboard", component: Dashboard, beforeEnter: multiguard([isLogin])},
        { path: "/payment", component: PaystackForm, beforeEnter: multiguard([isLogin])},
        { path: "/auth/verify-user", component: VerifyUser, beforeEnter: multiguard([isLogin,support_team])},
        { path: "/auth/verification", component: RequestVerify, beforeEnter: multiguard([isLogin])},
        { path: "/change-password", component: ChangePassword, beforeEnter: multiguard([isLogin])},
        { path: "/forgot-password", component: ForgotPassword},
        { path: "/reset-password", component: ResetPassword},
        { path: "*",component: NotFound},
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})