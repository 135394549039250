<template>
  <div :class="{ 'pt-10 grey lighten-2': $vuetify.breakpoint.smAndDown }">
    <div
      class="rounded-lg grey lighten-2"
      :class="{
        'pa-6': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div class="pa-4">
        <p class="mt-n6">Search By Location</p>
        <v-select
          style=""
          class=""
          background-color="white"
          :items="states"
          item-text="name"
          item-value="id"
          v-model="state"
          v-on:change="onChange"
          label="Select State"
          outlined
          dense
        >
        </v-select>
        <v-select
          class=""
          background-color="white"
          :items="lgas"
          label="select lga"
          item-text="name"
          item-value="id"
          outlined
          v-model="lga"
          dense
        >
        </v-select>
        <v-btn
          v-show="loading == false"
          @click="search"
          depressed
          color="#0062df"
          class="  darken-4 white--text"
        >
          Search
          <v-icon dark class="ml-2">
            mdi-magnify
          </v-icon>
        </v-btn>
        <v-progress-circular
          v-show="loading"
          :width="3"
          class="ml-4"
          color="green"
          indeterminate
        >
        </v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      loading: false,
      state: "",
      lga: "",
    };
  },
  computed: {
    ...mapState(["state_id", "lga_id"]),
    ...mapGetters(["states", "lgas"]),
  },
  methods: {
    ...mapMutations(["_setState", "_setLga", "_toggleLga"]),
    ...mapActions(["getLocation", "getLga", "getAgent"]),
    async search() {
      this.loading = true;
      this._setState(this.state);
      this._setLga(this.lga);
      console.log(this.state);
      await this.getAgent();
      this.loading = false;
    },

    onChange(event) {
      this.getLga(event);
      this._toggleLga(true);
    },
    async getval() {
      await this.getLocation();
    },
  },
  created() {
    this.getval();
  },
};
</script>
