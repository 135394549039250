<template>
  <v-app class="grey lighten-3" :class="{ 'px-16 py-10': $vuetify.breakpoint.smAndUp,  }">
    <div class="px-8 white">
        <h1 class="mt-8 title">Request Details</h1>
        <div class="mt-5 d-flex">
          <v-form ref="form" >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="3"
                class="mb-n7"
              >
                <v-text-field
                  label="Full Name"
                  :rules="[rules.required]"
                  hint="This field is required"
                  outlined
                  v-model="form.full_name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                class="mb-n7"
              >
                <v-text-field
                  label="Email"
                  outlined
                  type="email"
                  :rules="[rules.required]"
                  hint="This field is required"
                  v-model="form.email"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                class="mb-n7"
              >
                <v-text-field
                  label="Phone Number"
                  outlined
                  :rules="[rules.required]"
                  hint="This field is required"
                  v-model="form.phone"
                ></v-text-field>
              </v-col>
              <v-col
                  class="d-flex mb-n7"
                  cols="12"
                  sm="6"
                  md="3"
                >
                <v-select
                  :items="states"
                  item-text="name"
                  item-value="id"
                  label="State"
                  :rules="[rules.required]"
                  hint="This field is required"
                  outlined
                  v-model="form.state_id"
                  v-on:change="onChange"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
                class="mb-n7"
              >
                <v-select
                  :items="lgas"
                  item-text="name"
                  item-value="id"
                  label="City"
                  :rules="[rules.required]"
                  hint="This field is required"
                  outlined
                  v-model="form.lga_id"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                class="mb-n7"
              >
                <v-text-field
                  label="Area"
                  outlined
                  v-model="form.area"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                class="mb-n7"
              >
                <v-text-field
                  label="Budget"
                  :rules="[rules.required]"
                  hint="This field is required"
                  outlined
                  v-model="form.budget"

                ></v-text-field>
              </v-col>
              <v-col
                class="d-flex mb-n7"
                cols="12"
                sm="6"
                md="3"
              >
                <v-select
                  item-text="name"
                  item-value="value"
                  :items="propertytype"
                  label="Property type"
                  :rules="[rules.required]"
                  hint="This field is required"
                  outlined
                  v-model="form.property_type"
                ></v-select>
              </v-col>
          
              <v-col
                class="d-flex mb-n7"
                cols="12"
                sm="6"
                md="3"
              >
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="categories"
                  label="Category"
                  :rules="[rules.required]"
                  hint="This field is required"
                  outlined
                  v-model="form.category_id"
              ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                class="mb-n7"
              >
              <v-select
                  :items="subcategories"
                  item-text="name"
                  item-value="value"
                  label="Sub-category"
                  outlined
                  v-model="form.sub_category"
              ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                class="mb-n7"
              >
              <v-select
                :items="bedroom"
                item-text="name"
                item-value="value"
                label="Bedroom"
                outlined
                v-model="form.bedroom"
              ></v-select>
              </v-col>
              <v-col
              cols="12"
              md="12"
              class="mb-n7"
              >
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Description"
                  :rules="[rules.required]"
                  hint="This field is required"
                  v-model="form.description"
                  full-width
                  rows="7"
                  no-resize
                ></v-textarea>
              </v-col>
              <v-col>
                <v-btn
                  v-show="loading == false"
                  style="font-size: 15px"
                  depressed
                  dark
                  @click="send"
                  color="#0062DF"
                  class="font-weight-bold ml-5 py-5 mb-8 float-right"
                >
                  Send Request
                </v-btn>
                <v-progress-circular
                  v-show="loading" 
                  :width="3"
                  class="mb-4 float-right"
                  color="green"
                  indeterminate
                >
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </div>
      
    </div>
  </v-app>
</template>
<script>
import {mapActions  } from "vuex";
import {mapState  } from "vuex";
import {mapGetters  } from "vuex";
export default {
  metaInfo() {
		return {
			title: `Request Property | Housefinder`,			
		}
	},
  data: () => ({
    loading: false,
    success: false,
    form: {
      full_name: '',
      email: '',
      phone: '',
      state_id: '',
      lga_id: '',
      area: '',
      budget: '',
      category_id: '',
      property_type: '',
      description: '',
      sub_category: '',
      bedroom: '',
    }, 
    rules: {
      required: value => !!value || 'This Field Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
    },
    propertytype: [
        { name: "Select Property-type"},
        { name: "For Sale", value: "Sale" },
        { name: "For Rent", value: "Rent" },
        { name: "For Short Let", value: "For Short Let" },
    ],
    subcategories: [
      { name: "Detached", value: "Detached" },
      { name: "Semi Detached", value: "Semi Detached" },
      { name: "Furnished", value: "Funished" },
      { name: "Serviced", value: "Serviced" },
    ],
    bedroom: [
      { name: "Select number of bedrooms" },
      { name: "1", value: "1" },
      { name: "2", value: "2" },
      { name: "3", value: "3" },
      { name: "4", value: "4" },
      { name: "5+", value: "5+" },
    ],
  }),
  computed: {
    ...mapState({states: state => state.request.stateData}),
    ...mapGetters(["categories", "lgas"]),
  },
  methods: {
    ...mapActions({addRequest: "request/addRequest", getState: "request/getStates"}),
    ...mapActions(["getLga"]),
    async send(){
      if(this.$refs.form.validate()) {
        this.loading =true;
        let response = await this.addRequest(this.form)
        if(response.success){
          this.success = true;
          this.$toastr.s(response.message);
          this.$refs.form.reset()
          this.loading = false;
          this.$router.push(`/requests`);
        }
        else{
        this.loading = false;
        this.$toastr.e(response.message);
        }
      }
      else{
        this.$toastr.e('Some fields are missing');
        this.loading = false; 
      }
    },
    onChange(event) {
      console.log(event)
      this.getLga(event);
    },
  },
  created(){
    this.getState();
  }
};
</script>
<style scoped>

</style>
