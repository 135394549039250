<template>
  <div class="ml-20 mx-auto  d-none d-md-block" style="width: 900px">
    <div
      class="mb-n4 white rounded-lg"
      :class="{
        'search-top-lg': $vuetify.breakpoint.lgAndUp,
        'search-top-md': $vuetify.breakpoint.mdOnly,
      }"
    >
      <v-text-field
        filled
        placeholder="Search Property..."
        background-color="white"
        class="pa-2"
        dense
        flat
        color="grey lighten-1"
        v-model="searchWord"
        v-on:keyup.enter="search"
      >
      </v-text-field>
    </div>
    <v-card class="mt-n6 rounded-lg d-flex  mb-6 px-4" flat>
      <span>
        <v-autocomplete
          v-model="state"
          :loading="loading"
          v-on:change="onChange"
          :items="items"
          item-text="name"
          item-value="id"
          :search-input.sync="searchState"
          cache-items
          class="mr-4"
          hide-no-data
          hide-details
          label="Enter State"
        >
        </v-autocomplete>
        <span
          class="red--text text-subtitle-2"
          v-show="items.length == 0 && searchState != null"
          >State Not Found</span
        >
      </span>
      <span>
        <v-autocomplete
          v-model="lga"
          class="mr-4"
          :loading="loading"
          :items="lgaItems"
          item-text="name"
          item-value="id"
          :search-input.sync="searchLga"
          hide-no-data
          hide-details
          label="Enter City"
          :disabled="state == null || state == ''"
        >
        </v-autocomplete>
        <span
          class="red--text text-subtitle-2"
          v-show="lgaItems.length == 0 && searchLga != null"
          >Location Not Found</span
        >
      </span>
      <v-select
        style=""
        class="mr-4"
        label="Select Type"
        :items="PropertyType"
        item-text="name"
        item-value="value"
        v-model="property_type"
      >
      </v-select>
      <v-select
        style=""
        class="mr-4"
        v-model="cat"
        item-text="name"
        item-value="slug"
        :items="categories"
        label="Categories"
      >
      </v-select>
      <v-select
        style=""
        :items="priceItems"
        label="Price Range"
        item-text="name"
        v-model="price_range"
        item-value="value"
      >
      </v-select>
      <v-btn
        @click="search"
        color="#0062DF"
        class="mt-4 ma-2 darken-4 white--text"
      >
        <v-icon dark>
          mdi-magnify
        </v-icon>
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      showMore: false,
      cats: [],
      state: null,
      lga: null,
      searchWord: null,
      property_type: null,
      cat: null,
      bedroom_number: null,
      price_range: null,
      loading: false,
      items: [],
      lgaItems: [],
      searchState: null,
      searchLga: null,
      priceItems: [
        { name: "All Price", value: "" },
        { name: "Less than 50,000", value: "0-50000" },
        { name: "Less than 100,000", value: "0-100000" },
        { name: "Less than 250,000", value: "0-250000" },
        { name: "Less than 500,000", value: "0-500000" },
        { name: "Less than 750,000", value: "0-750000" },
        { name: "Less than 1,000,000", value: "0-1000000" },
        {
          name: "Above 1,000,000 and below 5,000,000",
          value: "1001000-5000000",
        },
        {
          name: "Above 5,000,000 and below 10,000,000",
          value: "5001000-10000000",
        },
        {
          name: "Above 10,000,000 and below 20,000,000",
          value: "10001000-20000000",
        },
        { name: "Above 20,000,000", value: "20000000-1000000000" },
      ],
      PropertyType: [
        { name: "Short Let", value: "For Short Let" },
        { name: "Buy", value: "For Sale" },
        { name: "Rent", value: "For Rent" },
      ],
      bedroom: [
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
        { name: "4", value: 4 },
        { name: "5", value: 5 },
        { name: "6", value: 6 },
        { name: "7", value: 7 },
        { name: "8", value: 8 },
        { name: "9", value: 9 },
        { name: "10", value: 10 },
      ],
    };
  },
  computed: {
    ...mapGetters(["categories", "states", "lgas"]),
  },
  methods: {
    ...mapActions(["setSearch", "getLga", "getLocation"]),
    ...mapMutations([
      "_setPriceRange",
      "_setState",
      "_setLga",
      "_setPropertyType",
      "clearFilter",
      "_setSearchWord",
      "_setBedroom",
      "_setSkeloader"
    ]),
    search() {
      this.clearFilter();
      this._setSkeloader(true);
      this._setState(this.state);
      this._setPriceRange(this.price_range);
      this._setSearchWord(this.searchWord);
      this._setPropertyType(this.property_type);
      this._setLga(this.lga);
      this._setBedroom(this.bedroom_number);

      if (this.searchWord) {
        let catSlug = this.cat ? this.cat : "all-categories";
        this.$router.push(`/properties/${catSlug}?query=${this.searchWord}`);
      } else {
        let catSlug = this.cat ? this.cat : "all-categories";
        this.$router.push(`/properties/${catSlug}`);
      }
    },
    async onChange(event) {
      if (event != null && event != "") {
        await this.getLga(event);
        this.lgaItems = this.lgas;
      } else {
        this.lgaItems = [];
      }
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter(e => {
          return (
            (e.name || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
          );
        });
        this.loading = false;
      }, 500);
    },
    querySelectionsLga(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.lgaItems = this.lgas.filter(e => {
          return (
            (e.name || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
          );
        });
        this.loading = false;
      }, 500);
    },
    async getData() {
      await this.getLocation();
      this.items = this.states;
    },
  },
  watch: {
    searchState(val) {
      val && val !== this.state && this.querySelections(val);
    },
    searchLga(val) {
      val && val !== this.lga && this.querySelectionsLga(val);
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style>
.search-top-lg {
  width: 400px;
  margin-top: -140px;
}
.search-top-md {
  width: 400px;
  margin-top: -110px;
}
</style>
