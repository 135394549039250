<template>
  <v-app>
    <div>
      <div class="header-gradient py-10 px-5" :class="{ 'px-16 ': $vuetify.breakpoint.smAndUp }">
          <h1 class="white--text">Terms & Conditions</h1>
      </div>
      <div class="px-5 pt-10 text-justify" :class="{ 'px-16 ': $vuetify.breakpoint.smAndUp }">
          <h1 class="title">Our Terms & Conditions</h1>
          <p class="mt-5">This page (together with the documents referred to on it) tells you the terms of use on which you may use our website housefinder.com.ng. Please read these Terms of Use carefully before you start to use the Site. By using our Site, you indicate that you accept these Terms of Use and that you agree to abide by them. 
            If you do not agree to these Terms of Use, please refrain from using our Site.
            </p>
            <h1 class="title"><i>Accessing our site</i></h1>
            <p class="mt-5">Access to our Site is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide on our Site without notice. We will not be liable if for any reason our Site is unavailable at any time or for any period.
            </p>
            <h1 class="title"><i>Intellectual Property Rights;</i></h1>
            <div class="ml-5" :class="{ 'ml-7': $vuetify.breakpoint.smAndUp }">
                <p><span class="mr-3">1.</span>We are act as Agents on all property leased on our site, In affiliation with all property owners. </p>
                <p><span class="mr-3">2.</span>All property displayed has been scrutinized thoroughly to validate the authenticity of each property displayed, and other means to purchase any property listed on our website with our knowledge, House finder can’t be held liable for any loss incurred. </p>
                <p><span class="mr-3">3.</span>Our status as the acting agents for all available material on our Site must always be acknowledged. </p>
                <p><span class="mr-3">4.</span>You must not use any part of the materials bearing our watermark on our Site (including but not limited, to photographs, property details, virtual tours and/or floor plans) for any commercial purpose without obtaining a license to do so from us. </p>
            </div>
            <h1 class="title"><i>Reliance on information posted;</i></h1>
            <div class="ml-5" :class="{ 'ml-7': $vuetify.breakpoint.smAndUp }">
                <p><span class="mr-3">1.</span>Commentary and other materials posted on our Site are not intended to amount to advice on which reliance should be placed. Users of the Site should not take or omit to take any action that relies on information on our Site. </p>
                <p><span class="mr-3">2.</span>While we make every effort to ensure that the materials on our Site are accurate and complete, we provide them for information only, so they are indicative rather than definitive. We therefore make no explicit or implicit guarantee of their accuracy, and, as far as applicable laws allow, we neither accept responsibility for errors, inaccuracies or omissions, nor for loss that may result directly or indirectly from reliance placed on such materials by any visitor to our Site. </p>
            </div>
            <h1 class="title"><i>Information relating to properties displayed on the site;</i></h1>
            <div class="ml-5" :class="{ 'ml-7': $vuetify.breakpoint.smAndUp }">
                <p><span class="mr-3">1.</span><b>Particulars:</b> Any property particulars are not an offer or contract, nor part of one. You should not rely on statements by us in the particulars or by word of mouth or in writing ("information") as being factually accurate about the property, its condition or its value. We nor any joint agent has any authority to make any representations about the property, and accordingly any information given is entirely without responsibility on the part of the agents, seller(s) or lessor(s). </p>
                <p><span class="mr-3">2.</span><b>Photos:</b> The photographs show only certain parts of the property as they appeared at the time they were taken. Areas, measurements and distances given are approximate only.</p>
                <p><span class="mr-3">3.</span><b>Regulations:</b> Any reference to alterations to, or use of, any part of the property does not mean that any necessary planning, building regulations or other consent has been obtained. A buyer or lessee must find out by inspection or in other ways that these matters have been properly dealt with and that all information is correct.</p>
                <p><span class="mr-3">4.</span><b>Brokerage:</b> The brokerage fee position relating to the property may change without notice.</p>
            </div>
            <h1 class="title"><i>Our site changes regularly</i></h1>
            <p class="mt-5">We aim to update our Site regularly, and may change the content (including any information relating to properties) at any time. Any of the material on our Site may be out of date at any given time </p>
            <h1 class="title"><i>Your concerns</i></h1>
            <p class="mt-5">If you have any concerns about material which appears on our Site, please contact <b>helpdesk@housefinder.com.ng</b></p>
      </div>
    </div>
  </v-app>
</template>
<script>
export default {
  metaInfo() {
		return {
			title: `Terms and Conditions - Housefinder.com.ng`,
			
		}
	},

};
</script>
<style scoped>
div {
    color: #4A425F;
}
div div .header-gradient {
  background: linear-gradient(91.83deg, #4a425f 0.62%, #896d19 100%);
}
</style>
