<template>
  <v-app>
    <div class="mb-n16">
      <div
        class="header-gradient py-14 px-5"
        :class="{ 'px-16 ': $vuetify.breakpoint.smAndUp }"
      >
        <h1 class="white--text">Contact Us</h1>
        <div class="mt-10">
          <v-icon class="mr-8 white--text">mdi-facebook</v-icon>
          <v-icon class="mr-8 white--text">mdi-instagram</v-icon>
          <v-icon class="mr-8 white--text">mdi-twitter</v-icon>
        </div>
      </div>
      <div
        class="px-5 py-16"
        :class="{ 'px-16 ': $vuetify.breakpoint.smAndUp }"
      >
        <v-row>
          <v-col cols="12" md="4" sm="12" class="mb-n16">
            <div>
              <h1 class="my-2 ml-1">CONTACT US AT</h1>
              <p>
                <v-icon class="mr-3">mdi-map-marker-outline</v-icon> No. 35 Ajose Adeogun street, Utako District Abuja, Nigeria
              </p>
              <p>
                <v-icon class="mr-3">mdi-clock-outline</v-icon>Mon – Fri: 8.00am – 5.00pm, Sat--Sun:   Closed 
              </p>
              <p>
                <v-icon class="mr-3">mdi-phone-outline</v-icon>+2349083737372
              </p>
              <p>
                <v-icon class="mr-3">mdi-email-outline</v-icon
                >info@housefinder.com.ng
              </p>
              <p>For appointments out of these hours, please make bookings via our website</p>
              <p>Thank you for visiting our Housefinder Technologies LTD</p>
            </div>
          </v-col>
          <v-col cols="12" md="8" sm="12" class="mt-lg-n16">
            <v-card
              class="py-16 px-5 items-center mt-lg-n16"
              :class="{ 'px-16 elevation-10': $vuetify.breakpoint.smAndUp, 'elevation-0 flat': $vuetify.breakpoint.xsOnly  }"
            >
              <h1 class="title">
                <v-icon class="mr-1">mdi-book</v-icon>Get in touch
              </h1>
              <v-form ref="form" >
                <v-alert v-show="success" class="mt-3 mb-n4" type="success">
                  Message Sent Successfully
                </v-alert>
                <v-row class="mt-10" no-gutters>
                  <v-col cols="12" md="12">
                    <label class="subtitle-2" for="Full Name">Full Name</label>
                    <v-text-field
                      solo
                      flat
                      background-color="#F0F0F0"
                      v-model="form.full_name"
                      :rules="[rules.required]"
                      hint="This field is required"
                    ></v-text-field>
                  </v-col>
                  <v-col class="-mt-10" cols="12" md="12">
                    <label class="subtitle-2" for="Email">Email</label>
                    <v-text-field
                      solo
                      flat
                      background-color="#F0F0F0"
                      v-model="form.email"
                      type="email"
                      :rules="[rules.required]"
                      hint="This field is required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <label class="subtitle-2" for="Phone Number"
                      >Phone Number</label
                    >
                    <v-text-field
                      solo
                      flat
                      background-color="#F0F0F0"
                      v-model="form.phone_number"
                      :rules="[rules.required]"
                      hint="This field is required"
                    ></v-text-field>
                  </v-col>
                    <v-col cols="12" md="12">
                    <label class="subtitle-2" for="Phone Number"
                      >Subject</label
                    >
                    <v-text-field
                      solo
                      flat
                      background-color="#F0F0F0"
                      v-model="form.subject"
                      :rules="[rules.required]"
                      hint="This field is required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <label class="subtitle-2" for="Message">Message</label>
                    <v-textarea
                      solo
                      flat
                      background-color="#F0F0F0"
                      v-model="form.message"
                      :rules="[rules.required]"
                      hint="This field is required"
                    ></v-textarea>
                  </v-col>
                  <v-col class="text-center  mt-5">
                    <v-btn  v-show="loading == false"  @click="send" depressed id="v-btn" block class="white--text py-md-6">
                      Send Now
                    </v-btn>
                    <v-progress-circular
                      v-show="loading" 
                      :width="3"
                      class="mb-4 "
                      color="green"
                      indeterminate
                    >
                    </v-progress-circular>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>
<script>
import {mapActions  } from "vuex";

export default {
   metaInfo() {
		return {
			title: `Contact | Housefinder.com.ng`,
			
		}
	},
  data(){
    return{
      loading: false,
      success: false,
      form: {
        full_name: '',
        email: '',
        phone_number: '',
        subject: '',
        message: '',
      }, 
      rules: {
        required: value => !!value || 'This Field Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
      },
    }
  },
  methods: {
    ...mapActions(["sendContactMail"]),
    async send(){
      if( this.$refs.form.validate()) {
        this.loading =true;
        let response = await this.sendContactMail(this.form)
        if(response.success){
          this.success = true;
          this.$toastr.s(response.message);
          this.$refs.form.reset()
        }
        this.loading = false; 
      }
      else{
        this.$toastr.e('Some fields are missing');
        this.loading = false; 
      }
    }
  }
};
</script>
<style scoped>
div {
  color: #4a425f;
}
div div .header-gradient {
  background: linear-gradient(91.83deg, #4a425f 0.62%, #896d19 100%);
}

#v-btn {
  background-color: #896d19;
}
</style>
