<template>
	<div>
		<v-container class="d-none d-md-block"  :class="{'mt-4': $vuetify.breakpoint.smAndDown,
			'mt-16': $vuetify.breakpoint.mdAndup, 'mb-10': $vuetify.breakpoint.mdAndUp,
			'py-8': $vuetify.breakpoint.smAndDown}"
		>
			<v-row color="indigo lighten-5" class="mt-5 justify-center">
				<v-col md="2" v-for="(n, index) in partners" :key="index" class="text-center">
				
					<v-img :src="n.img" alt="" class="" :height="n.height" contain/>
				
				</v-col>
			</v-row>
		</v-container>

		<!--mobile-->
		<v-container  class="d-md-none mb-16">
      <v-sheet
        class="mx-auto"
        max-width="800"
      >
        <v-slide-group
      
          class=""
          active-class="success"
          show-arrows
        >
          <v-slide-item
            v-for="(i, index) in partners"
            :key="index"
            v-slot="{ active, toggle }"
          >
            <v-card
              :color="active ? undefined : 'grey lighten-1'"
              class="ma-4"
              height="40"
              width="100"
              @click="toggle"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <v-card class=" ">
                  <v-img :src="i.img" alt="" class="" width="100" height="50" contain/>
                </v-card>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-container>
	</div>
</template>

<script>

export default {
	data(){
		return {
			partners: [
				{
					title: 'VFD',
					height: '30',
					img: require('../../assets/partners/image-1.png')
				},
				{
					title: 'Trust Plot',
					height: '50',
					img: require('../../assets/partners/image-2.png')
				},
				{
					title: 'Peace',
					height: '40',
					img: require('../../assets/partners/image-4.png')
				},
				{
					title: 'Mater',
					height: '45',
					img: require('../../assets/partners/mastercard-5.png')
				},
				{
					title: 'iTex',
					height: '25',
					img: require('../../assets/partners/image-6.png')
				},
      ],
		}
	}
}
</script>