import Vue from "vue";
import Vuex from "vuex";
import AuthModule from "./Auth";
import customAxios from '../helpers/AxiosInstance';
import * as url from '../store/IndexStoreRoute';
import requestModule from "./Request";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    modules: {auth: AuthModule, request: requestModule},
    state: {
        editedProduct: "",
        featuredProducts: [],
        categoriesData: [],
        countries: [],
        products: [],
        states: [],
        lgas: [],
        categories: [],
        //productsTotal: ' ',
        currentPage: 1,
        pageSize: 8,
        currentCategory: "",
        pages: [],
        serverPageCount: 0,
        searchWord: "",
        showSearch: false,
        sort: "",
        priceRange: "",
        listing: "",
        state_id: "",
        lga_id: "",
        property_type: "",
        toggleLga: false,
        property_status: null,
        productListLoading: false,
        skeloader: true,
        AgentDetail:'',
        bedroom: '',
    },
    getters: {
        //productsFilteredByCategory: state => state.products
            //.filter(p => state.currentCategory == "All" || p.category == state.currentCategory),
        productById:(state) => (id) => {
            return state.products.find(p => p.id == id);
        },
        isLogin(){
            let login = localStorage.getItem('login');
            return login;
        },
        processedProducts: (state) => {
            return state.pages[state.currentPage];
        },
        processedAgents: (state) => {
            return state.pages[state.currentPage];
        },
        pageCount: (state) => state.serverPageCount,
        categories: state =>  [{id: '', name:'All Categories', slug:'all-categories', featured: '', menu: '',
            image: '', created_at: '', updated_at: ''}, ...state.categoriesData
        ],
        states: state =>  [{id: '', name:'All states', created_at: '', updated_at: ''}, ...state.states],
        lgas: state =>  [{id: '', state_id: '', name:'All Lga', created_at: '', updated_at: ''}, ...state.lgas],

    },
    mutations: {
        _toggleLga(state, data){
            state.toggleLga = data;
        },
        _setSkeloader(state, data){
            state.skeloader = data;
        },
        _setState(state, data){
            state.state_id = data;
        },
        clearFilter(state) {
            state.sort = "";
            state.priceRange  =  "";
            state.listing  =  "";
            state.state_id  =  "";
            state.lga_id  =  "";
            state.property_type  = "";
            state.searchWord = "";
            state.bedroom = "";
        },
        _setLga(state, data){
            state.lga_id = data;
        },
        _setPropertyType(state, data){
            state.property_type = data;
        },
        setEditProduct(state, data){
            state.editedProduct = data.pdata;
        },
        _setCurrentPage(state, page) {
            state.currentPage = page;
        },
        _setPageSize(state, size) {
            state.pageSize = size;
            state.currentPage = 1;
        },
        _setCurrentCategory(state, category) {
            state.currentCategory = category;
            state.currentPage = 1;
        },
        _setSort(state, sort) {
            state.sort = sort;
            state.currentPage = 1;
        },
        _propertyStatus(state, status) {
            state.property_status = status;
            state.currentPage = 1;
        },
        _setListing(state, listed) {
            state.listing = listed;
            state.currentPage = 1;
        },
        _setPriceRange(state, priceRange) {
            state.priceRange = priceRange;
            state.currentPage = 1;
        },
        _setSearchWord(state, word) {
            state.searchWord = word;
            state.currentPage = 1;
        },
        _setBedroom(state, room) {
            state.bedroom = room;
            state.currentPage = 1;
        },
        setData(state, data){
            state.countries = data.cdata;
            state.states = data.sdata;
           state.categories = data.catdata;
        },
        _setFeaturedProduct(state, data){
            state.featuredProducts = data.pdata;
        },
        addPage(state, page) {
            if(page.data.length > 0) {
                for (let i = 0; i < page.pageCount; i++) {
                    Vue.set(state.pages, page.number + i,
                    page.data.slice(i * state.pageSize,
                    (i * state.pageSize) + state.pageSize));
                }
                state.productListLoading = false;
            }
            else{
                state.pages.splice(0, state.pages.length);
                state.productListLoading = true;
            }
        },
        clearPages(state) {
            state.pages.splice(0, state.pages.length);
        },
        setCategories(state, categories) {
            state.categoriesData = categories;
        },
        setPageCount(state, count) {
            state.serverPageCount = Math.ceil(Number(count) / state.pageSize);
        },

        setLga(state, data){
            state.lgas = data.ldata;
        },
        removeProduct(state, id){
            let index = state.products.findIndex(p => p.id == id);
            state.products.splice(index, 1);
        },
        removeCategory(state, id){
            let index = state.categories.findIndex(c => c.id == id);
            state.categories.splice(index, 1);
        },
        _updateFeatureProduct(state, data){
            let index = state.products.findIndex(p => p.id == data.id);
            state.products[index].featured = data.featured;
        },
        _updatePublishProduct(state, data){
            let index = state.products.findIndex(p => p.id == data.id);
            state.products[index].publish = data.publish;
        },
        setProduct(state, data){
            state.products = data.pdata;
            state.productsTotal = data.pdata.length;
        },
        setShowSearch(state, show) {
            state.showSearch = show;
        },
        setAgentDetail(state, data){
            state.AgentDetail = data
        }
    },
    actions: {
        async getHomeData(context) {
            //await context.dispatch("getPage", 2);
            context.commit("setCategories", (await  customAxios.get(url.categoryProductUrl)).data.data);   
        },
        async getProductList(context) {
            await context.dispatch("getPage", 2);
            //context.commit("setCategories", (await Axios.get(categoryProductUrl)).data.data);   
        },
        async getUserProducts(context) {
            await context.dispatch("userProduct");
            //context.commit("setCategories", (await Axios.get(categoryProductUrl)).data.data);   
        },
        async findProduct(context, slug) {
            let pdata = (await customAxios.get(url.productUrl+'/'+slug)).data
            context.commit("setEditProduct", {pdata});   
        },
        async findUserProduct(context, slug) {
            let pdata = (await customAxios.get(url.findUserProductUrl+'/'+slug)).data
            //context.commit("setEditProduct", {pdata});
            return pdata   
        },
        async getCategories(context) {
            context.commit("setCategories", (await  customAxios.get(url.categoryProductUrl)).data.data);   
        },
        async getPage(context, getPageCount = 1) {
            let productUrl = `${url.paginatedProductUrl}?page=${context.state.currentPage}`;
            if (context.state.currentCategory != "" && context.state.currentCategory != null) {
                productUrl += `&category=${context.state.currentCategory}`; 
            }
            if (context.state.searchWord != "" && context.state.searchWord != null) {
                productUrl += `&query=${context.state.searchWord}`;
            }
            if (context.state.sort != "" && context.state.sort != null) {
                productUrl += `&order_field=id&order_type=${context.state.sort}`;
            }
            if (context.state.priceRange != "" && context.state.priceRange != null) {
                productUrl += `&price=${context.state.priceRange}`;
            }
            if (context.state.listing != "" && context.state.listing != null) {
                productUrl += `&listed=${context.state.listing}`;
            }
            if (context.state.bedroom != "" && context.state.bedroom != null) {
                productUrl += `&bedroom=${context.state.bedroom}`;
            }
            if (context.state.state_id != "" && context.state.state_id != null) {
                productUrl += `&state_id=${context.state.state_id}`;
            }
            if (context.state.lga_id != "" && context.state.lga_id != null) {
                productUrl += `&lga_id=${context.state.lga_id}`;
            }
            if (context.state.property_type != "" && context.state.property_type  != null) {
                productUrl += `&product_type=${context.state.property_type}`;
            }
            let response = await  customAxios.get(productUrl);
            console.log(getPageCount)
            context.commit("setPageCount", response.data.total);
            context.commit("addPage", { number: context.state.currentPage,
            data: response.data.data, pageCount: response.data.total});
            context.state.skeloader == false;
        },
        setCurrentPage(context, page) {
            context.commit("_setCurrentPage", page);
            if (context.state.pages[page] == '') {
                context.dispatch("getPage");
            }
        },
        _setUserProduct(context, page) {
            context.commit("_setCurrentPage", page);
            if (context.state.pages[page] == '') {
                context.dispatch("userProduct");
            }
        },
        setPageSize(context, size) {
            context.commit("clearPages");
            context.commit("_setPageSize", size);
            context.dispatch("getPage", 2);
        },
        async setCurrentCategory(context, category) {
            context.commit("clearPages");
            context.commit("_setCurrentCategory", category);
            await context.dispatch("getPage", 2);
        },
        async setSort(context, sort) {
            context.commit("clearPages");
            context.commit("_setSort", sort);
            await context.dispatch("getPage", 2);
        },
        async setState(context, state) {
            context.commit("clearPages");
            context.commit("_setState", state);
            await context.dispatch("getPage", 2);
        },
        async setLga(context, lga) {
            context.commit("clearPages");
            context.commit("_setLga", lga);
            await context.dispatch("getPage", 2);
        },
        async setBedroom(context, room) {
            context.commit("clearPages");
            context.commit("_setBedroom", room);
            await context.dispatch("getPage", 2);
        },
        async setListing(context, listed) {
            context.commit("clearPages");
            context.commit("_setListing", listed);
            await context.dispatch("getPage", 2);
        },
        async setPriceRange(context, price) {
            context.commit("clearPages");
            context.commit("_setPriceRange", price);
            await context.dispatch("getPage", 2);
        },
        async setPropertyType(context, data) {
            context.commit("clearPages");
            context.commit("_setPropertyType", data);
            await context.dispatch("getPage", 2);
        },
        search(context, term) {
            context.commit("setSearchTerm", term);
            context.commit("clearPages");
            context.dispatch("getPage", 2);
        },
        clearSearchTerm(context) {
            context.commit("setSearchTerm", "");
            context.commit("clearPages");
            context.dispatch("getPage", 2);
        },
        async setSearch(context) {
            context.commit("clearPages");
            await context.dispatch("getPage", 2);
        },
        async getLocation(context) {
            let cdata = (await  customAxios.get(url.countriesUrl)).data.data;
            let sdata = (await  customAxios.get(url.stateUrl)).data.data;
            context.commit("setData", {cdata, sdata} );
        },
        async getFeaturedProduct(context) {
            let pdata = (await  customAxios.get(url.featuredProductUrl)).data.data;
            console.log(pdata);
            context.commit("_setFeaturedProduct",{pdata});
        },
        async getLga(context, id) {
            let ldata = (await  customAxios.get(url.lgaUrl+'/'+id)).data.data;
            context.commit("setLga", {ldata} );
        },
        async addProduct(context, formData) {
            let pdata = (await customAxios.post(url.productUrl,formData)).data;
            
            return pdata;
        },
        async updateProduct(context, formData) {
            let id = formData.id;
            let pdata = (await customAxios.put(url.productUrl+'/'+id,formData)).data;
            return pdata;
        },
        async addCategory(context, formData) {
            let pdata = (await customAxios.post(url.categoryUrl,formData)).data;
            return pdata;
        },
        async getProducts(context) {
            let pdata = (await customAxios.get(url.productUrl)).data.data;

            context.commit("setProduct", {pdata} );
        },
        async deleteProduct(context, id) {
            let pdata = (await customAxios.delete(url.productUrl+'/'+id)).data;
            if (pdata.success == true){
            context.commit("removeProduct",id);
            }
            return pdata;
        },
        async deleteCategory(context, id) {
            let cdata = (await customAxios.delete(url.categoryUrl+'/'+id)).data;
            if (cdata.success == true){
            context.commit("removeCategory",id);
            }
            return cdata;
        },
        async updateCategory(context, formData) {
            let cdata = (await customAxios.post(url.categoryUrl+'/update', formData)).data;
            return cdata;
        },
        async adminCategories(context) {
            let cat = (await customAxios.get(url.categoryUrl)).data.data;  
            context.commit("setCategories", cat) 
        },
        async publishProduct(context, formData) {
            let pdata = (await customAxios.post(url.publishUrl,formData)).data;
            context.commit("_updatePublishProduct", formData);
            return pdata;
        },
        async featuredProduct(context, formData) {
            let pdata = (await customAxios.post(url.featuredUrl,formData)).data;
            context.commit("_updateFeatureProduct", formData);
            return pdata;
        },
        async userProduct(context) {
           
            let userProductUrl = `${url.userProducts}?page=${context.state.currentPage}`;
            if (context.state.currentCategory != "" && context.state.currentCategory != null) {
                userProductUrl += `&category=${context.state.currentCategory}`; 
            }
            if (context.state.sort != "" && context.state.sort != null) {
                userProductUrl += `&order_field=id&order_type=${context.state.sort}`;
            }
            if (context.state.listing != "" && context.state.listing != null) {
                userProductUrl += `&listed=${context.state.listing}`;
            }
          
            if (context.state.property_type != "" && context.state.property_type != null) {
                userProductUrl += `&product_type=${context.state.property_type}`;
            }
            if (context.state.property_status != "" && context.state.property_status != null) {
                userProductUrl += `&product_status=${context.state.property_status}`;
            }
            if (context.state.property_status == 0) {
                userProductUrl += `&product_status=${context.state.property_status}`;
            }
            let response = await customAxios.get(userProductUrl);
            context.commit("setPageCount", response.data.total);
            context.commit("addPage", { number: context.state.currentPage,
            data: response.data.data, pageCount: response.data.total});
        },
        async getAgent(context) {
            let AgentUrl = `${url.agentUrl}?page=${context.state.currentPage}`;
            
            if (context.state.state_id != "") {
                AgentUrl += `&state_id=${context.state.state_id}`;
            }
            if (context.state.lga_id != "") {
                AgentUrl += `&lga_id=${context.state.lga_id}`;
            }
            let response = await  customAxios.get(AgentUrl);
            context.commit("setPageCount", response.data.total);
            context.commit("addPage", { number: context.state.currentPage,
            data: response.data.data, pageCount: response.data.total});
        },
        _setAgentCurrentPage(context, page) {
            context.commit("_setCurrentPage", page);
            if (context.state.pages[page] == '') {
                context.dispatch("getAgent");
            }
        },
        async getAgentProducts(context, agentId) {
            let AgentProductUrl = `${url.agentProductUrl}/${agentId}?page=${context.state.currentPage}`;
            let response = await  customAxios.get(AgentProductUrl);
            context.commit("setPageCount", response.data.total);
            context.commit("setAgentDetail", response.data.user);
            context.commit("addPage", { number: context.state.currentPage,
            data: response.data.products, pageCount: response.data.total});
        },
        _setAgentProductCurrentPage(context, data) {
            context.commit("_setCurrentPage", data.page);
            if (context.state.pages[data.page] == '') {
                context.dispatch("getAgentProducts", data.agentId);
            }
        },
        
    }
})