<template>
  <div class="mt-4">
    <v-container>
      <h2 class="mt-4 ml-md-14">Agents Details</h2>
      <v-row>
        <v-col md="8">
          <v-tabs
            class="mb-8"
            v-model="tab"
            align-with-title
            
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab
              
            >
              Products
            </v-tab>
            <v-tab
            >
              About
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              
            >
            
              <div v-if="skeloader || processedProducts == false">
                <skeleton-loader/>
              </div>
              <div v-if="skeloader == false">
                <v-row v-for="product in processedProducts" v-bind:key="product.id" class="rounded-lg mb-1" style="border: 1px solid #E0E0E0">
						<v-col cols="12" sm="5">
							<router-link class="text-decoration-none black--text" :to="{ path: `/product-details/${product.slug}` }">
								<v-img
									height="230px"
									:src="product.product_images.length > 0? product.product_images[0].full: ''"
									
									>
								</v-img>
							</router-link>
						</v-col>
						<v-col cols="12" sm="7" class="mb-4">
							<router-link class="text-decoration-none black--text" :to="{ path: `/product-details/${product.slug}` }">
								<div class="mt-n4 mt-sm-3 ">
									<div class="cyan--text text--darken-4 font-weight-bold">
										₦ {{product.converted_price}} 
										<span class="px-1 text-caption font-weight-normal float-right lime darken-3 white--text"> 
											{{product.product_type}} 
										</span>
									</div>
									<span class="text-caption">{{product.duration}}</span> 
								</div>
								<v-divider class="divider-color" width="50"></v-divider>
								<div style="height:100px">
									<h3 class="mt-2 mb-4 grey--text text--darken-3">
										{{product.title | truncate(65) }}
									</h3>
									<v-icon
										small
									>
										mdi-map-marker
									</v-icon>
									<span class="text-subtitle-2 grey--text text-lighten-2 hidden-xs-only"> 
										{{`${product.state.name}, ${product.lga.name}`}}
									</span>
									<span class="text-subtitle-2 grey--text text-lighten-2 hidden-sm-and-up"> 
										{{`${product.state.name}, ${product.lga.name}`}}
									</span>
									<div>
										<span class="text-caption">{{product.listed_in}}</span>
										<v-icon
											v-show="product.bathroom"
											class="ml-4"
											small
											>
												mdi-shower
										</v-icon>
										<span  class="text-caption ml-1">{{product.bathroom}}</span>
										<v-icon
											v-show="product.bedroom"
											class="ml-4"
											small
											>
												mdi-bed
										</v-icon>
										<span  class="text-caption ml-1">{{product.bedroom}}</span>
									</div>
								</div>
							</router-link>
							<router-link  class="text-decoration-none black--text " :to="{ path: `/agent/products/${product.user.id}` }">
								<div class="text-subtitle-2 mt-10 mb-n4 text-capitalize" 
									
								>
									Agent:
									<span class="lime--text text--darken-4 ml-2 ">
										{{
                      product.user.user_type == 'admin_can_add'
                      ? 'Housefinder'
                      :product.user.agent.title
                        ? product.user.agent.title
                        : product.user.full_name
                    }}
									</span>
									<v-icon v-show="product.user.verify && product.user.verify.status == 1" small class="green--text ">
										mdi-check-decagram
									</v-icon>
								</div>
							</router-link>
              <div  v-show="product.developer" class=" mt-5 text-caption text-capitalize ">
								developer: <span class="grey--text text-lighten-2"> {{product.developer}}</span>
							</div>
						</v-col>
					</v-row>
                <page-control/>
                <v-container v-if=" productListLoading == true">
                  <v-sheet
                  class="pa-12"
                  color="grey lighten-3"
                  >
                  <v-sheet
                    :elevation="elevation"
                    class="mx-auto text-center"
                    
                    color="grey lighten-3"
                  ><div >
                    No Products Found
                  </div></v-sheet>
                  
                  
                  </v-sheet>
                </v-container>
              </div>
            </v-tab-item>
            <v-tab-item
              
            >
              <v-card flat>
                <v-card-text >
                  <div style=" border: 1px solid #E0E0E0">
                    <v-simple-table height="400px" class="text-capitalize">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Full Name
                            </th>
                            <th class="text-left">
                              {{AgentDetail.first_name+' '+AgentDetail.last_name}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Gender</td>
                            <td>{{AgentDetail.gender}}</td>
                          </tr>
                          <tr>
                            <td>Nationality</td>
                            <td>{{AgentDetail.nationality.name}}</td>
                          </tr>
                          <tr>
                            <td>State</td>
                            <td>{{AgentDetail.state.name}}</td>
                          </tr>
                          <tr>
                            <td>About</td>
                            <td>{{AgentDetail.agent.about}}</td>
                          </tr>
                          <tr>
                            <td>Phone Number</td>
                            <td>{{AgentDetail.phone_number}}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations} from "vuex";
import SkeletonLoader from '../layout/SkeletonLoader';
import PageControl from '../layout/AgentProductsControl';

export default {
  components: {PageControl,SkeletonLoader},
  metaInfo() {
		return {
			title: `Agent Houses, Lands & Apartments For Rent and Sale in Abuja, Lagos, Nigeria,  | Housefinder.com.ng`,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: `List of Flats for Sale in Nigeria, Real Estate Property Houses Apartments For Rent! best deals in Nigeria - Housefinder.com.ng`
				}
			]
		}
	},
  data(){
    return {
      tab: null,
      skeloader: true,
    }
  },
  computed: {
    ...mapState(["productListLoading", "AgentDetail"]),
    ...mapGetters(["processedProducts"]),
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    ...mapActions(["getAgentProducts"]),
    ...mapMutations(["_setCurrentPage"]),
    async getData(){
      this._setCurrentPage(1)
      this.skeloader = true;
      await this.getAgentProducts(this.$route.params.id);
      this.skeloader = false;
      console.log(this.AgentDetail)
       console.log(this.processedProducts)
    }
  },
  created(){
    this.getData();
  }
}
</script>